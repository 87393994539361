import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { countries, Country } from "country-data";
import axios, { AxiosResponse } from "axios";
import API from "src/utils/authConfig";
import toast from "react-hot-toast";

export const fetchData = createAsyncThunk("data/fetchData", async (val) => {
    try {
        const data: { name: string }[] = await API.get("/location.php");
        const countryData: { code: string; label: string }[] = [];
        data.forEach((location: { name: string }) => {
            const countryCode: Country | undefined = countries.all.find(
                (t) => t.name === location.name
            );
            if (countryCode !== undefined) {
                countryData.push({
                    code: countryCode.alpha2,
                    label: location.name,
                });
            }
        });

        return countryData;
    } catch (error: any) {
        toast.error(error.message);
    }
});

export const getLocation = createAsyncThunk(
    "data/fetchLocation",
    async (location: string) => {
        try {
            const data = await API.get(`/language.php?location=${location}`);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);

export const formSubmit = createAsyncThunk(
    "data/formSend",
    async (val: any) => {
        try {
            if (Object.keys(val).length !== 0) {
                const data = await API.post("/dashboard.php", val);
                return data;
            }
            const data = await API.get("/dashboard.php");
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);

const dataSlice = createSlice({
    name: "data",
    initialState: {
        countryCode: [],
        selectedLocation: [],
        dashboardData: [],
        status: "idle",
        formLoading: false,
        error: null,
    } as any,
    reducers: {
        resetSelectedLocation: (state) => {
            state.selectedLocation = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.formLoading = false;
                state.countryCode = action.payload;
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.formLoading = true;
                // state.error = action.error.message;
            })
            .addCase(getLocation.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getLocation.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.selectedLocation = action.payload;
            })
            .addCase(getLocation.rejected, (state, action) => {
                state.status = "failed";
                // state.error = action.error.message;
            })
            .addCase(formSubmit.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(formSubmit.fulfilled, (state, action) => {
                state.formLoading = false;
                state.dashboardData = action.payload;
            })
            .addCase(formSubmit.rejected, (state, action) => {
                state.formLoading = false;
                // state.error = action.error.message;
            });
    },
});
export const { resetSelectedLocation } = dataSlice.actions;
export default dataSlice.reducer;

import { configureStore, combineReducers, createAction } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import eventReducer from "./slices/event";
import uiReducer from "./slices/ui";
import themeReducer from "./slices/theme";
import chatUISlice from "./slices/chat-ui";
import dataSlice from "./slices/analytics-dashboard";
import Keywords from "./slices/keywods";
import RankTracker, { addProjectInfo } from "./slices/rank-tracker";
import BackLinks from "./slices/backlinks";
import auth from "./slices/auth";
import websiteAudit from "./slices/website-audit";
import marketAnalysis from "./slices/market-analysis";
import packages from "./slices/packages";
import MyCompetitors from "./slices/my-competitors";
import competitorResearch from "./slices/competitor-research";
import admin from "./slices/admin";
import account from "./slices/account";
import searchConsole from "./slices/searchConsole";
import onPage from "./slices/onpages";
import onpageProject from "./slices/onpage-project";
import Cookies from "js-cookie";
import onPageProjectId from "./slices/onpage-project/onPageProjectId";
import issueReport  from "./slices/issue-report"

// Define persist config
const persistConfig = {
  key: "optilinko",
  version: 1.1,
  storage,
};

// Combine reducers
const appReducer = combineReducers({
    websiteAudit,
    events: eventReducer,
    ui: uiReducer,
    chatUI: chatUISlice,
    theme: themeReducer,
    data: dataSlice,
    keywords: Keywords,
    RankTracker: RankTracker,
    myCompetitors: MyCompetitors,
    backLinks: BackLinks,
    marketAnalysis: marketAnalysis,
    packages: packages,
    competitorResearch: competitorResearch,
    admin,
    auth,
    account,
    searchConsole,
    onPage,onpageProject,onPageProjectId,issueReport
});

// Define the reset state action
export const RESET_STATE = "RESET_STATE";
export const resetState = createAction(RESET_STATE);

// Create the root reducer that handles the reset action
const rootReducer = (state: any, action: { type: string }) => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }
  return appReducer(state, action);
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

// Create the persistor
export const persistor = persistStore(store);

// Define types for RootState and AppDispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Define a function to reset all state and purge persisted data
export const resetAllState = async () => {
  return new Promise((res, rej) => {
    try {
      localStorage.clear();
      Cookies.remove("token");
      store.dispatch(resetState());
      store.dispatch(addProjectInfo({}));
      persistor.purge();
      res(true);
    } catch (error) {
      rej(error);
    }
  });
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { getRoleByNumber } from "src/helpers/data-helper";
import { ensureObject } from "src/helpers/formater-helper";
import API from "src/utils/authConfig";

interface IFormValues {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
}
interface SignInFormValues {
    email: string;
    password: string;
}

export const signUpForm = createAsyncThunk(
    "data/formSubmit",
    async (values: IFormValues) => {
        try {
            const { data } = await axios.post(
                "https://demo.optilinko.com/server/signup.php",
                values
            );
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const SignInForm = createAsyncThunk(
    "data/logInformSubmit",
    async (values: SignInFormValues) => {
        try {
            const { data } = await axios.post(
                "https://demo.optilinko.com/server/loginnew.php",
                values
            );

            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const TwoFaSubmit = createAsyncThunk(
    "data/TwoFaSubmit",
    async (values: { pin: string; token: string }) => {
        try {
            const { data } = await axios.post(
                "https://demo.optilinko.com/server/two_step.php",
                values
            );

            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const profileSetting = createAsyncThunk(
    "data/profileSetting",
    async (val?: any) => {
        try {
            const data = await API.post("/profile_setting.php", val);
            if (val?.status === 1) {
                toast.success("Profile updated successfully");
            }
            return data;
        } catch (error) {
            toast.error("Somthing went's wrong");
        }
    }
);
export const profilePic = createAsyncThunk(
    "data/profilePic",
    async (val?: any) => {
        try {
            const data = await API.post("/profile_setting.php", val, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            toast.success("Profile pic updated successfully");
            return data;
        } catch (error) {
            toast.error("Somthing went's wrong");
        }
    }
);
const dataSlice = createSlice({
    name: "auth",
    initialState: {
        signUp: {},
        user: {} as {
            first_name: string;
            last_name: string;
            email: string;
            phone: string;
            address: string;
            profile_pic: string;
            u_type: number | string;
            role: "Sub Admin" | "Manager / Accountant" | "User";
        },
        token: "",
        id: "",
        status: "idle",
        formStatus: "",
        error: null,
        fetchLoading: false,
        editProfileLoading: false,
        passwordUpdateLoading: false,
        profilePicLoading: false,
        twoFaLoading: false,
        resendPinLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(signUpForm.pending, (state) => {
                state.status = "loading";
            })
            .addCase(signUpForm.fulfilled, (state, action) => {
                state.status = "succeeded";
                // state.user = action.payload?.data;
                // state.token = action.payload?.data?.token;
            })
            .addCase(signUpForm.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(SignInForm.pending, (state) => {
                state.status = "loading";
            })
            .addCase(SignInForm.fulfilled, (state, _action) => {
                state.status = "succeeded";
                // const role = getRoleByNumber(action.payload?.data?.u_type);
                // state.user = { ...action.payload?.data, role: role };
                // state.token = action.payload?.data?.token;
            })
            .addCase(SignInForm.rejected, (state, action) => {
                state.status = "failed";
            })
            .addCase(TwoFaSubmit.pending, (state) => {
                state.twoFaLoading = true;
            })
            .addCase(TwoFaSubmit.fulfilled, (state, action) => {
                state.twoFaLoading = false;
                const role = getRoleByNumber(action.payload?.data?.u_type);
                state.user = { ...action.payload?.data, role: role };
                state.token = action.payload?.data?.token;
            })
            .addCase(TwoFaSubmit.rejected, (state, action) => {
                state.twoFaLoading = false;
            })
            .addCase(
                profileSetting.pending,
                (
                    state,
                    {
                        meta: {
                            arg: { status },
                        },
                    }
                ) => {
                    if (!status) {
                        state.fetchLoading = true;
                    } else if (status === 1) {
                        state.editProfileLoading = true;
                    } else if (status === 2) {
                        state.passwordUpdateLoading = true;
                    }
                }
            )
            .addCase(
                profileSetting.fulfilled,
                (
                    state,
                    {
                        payload,
                        meta: {
                            arg: { status },
                        },
                    }
                ) => {
                    if (!status) {
                        state.fetchLoading = false;
                        const role = getRoleByNumber((payload as any)?.u_type);
                        state.user = { ...(payload as any), role: role };
                    } else if (status === 1) {
                        state.editProfileLoading = false;
                        const role = getRoleByNumber((payload as any)?.u_type);
                        state.user = { ...(payload as any), role: role };
                    } else if (status === 2) {
                        state.passwordUpdateLoading = false;
                    }
                }
            )
            .addCase(profileSetting.rejected, (state) => {
                state.fetchLoading = false;
            })
            .addCase(profilePic.pending, (state) => {
                state.profilePicLoading = true;
            })
            .addCase(profilePic.fulfilled, (state, action) => {
                state.profilePicLoading = false;
                const payload = action?.payload;
                const role = getRoleByNumber((payload as any)?.u_type);
                state.user = { ...(payload as any), role: role };
            })
            .addCase(profilePic.rejected, (state) => {
                state.profilePicLoading = false;
            });
    },
});
export default dataSlice.reducer;

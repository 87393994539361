import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import API from "src/utils/authConfig";

export const issueReport = createAsyncThunk(
    "data/issueReport",
    async () => {
        try {
            const data = await API.get("https://demo.optilinko.com/server/issue.php");
            // console.log(data, "issue report ----- api");
            return data;
        } catch (error: any) {
            toast.error(error.message);
}
    }
);

const issueReportSlice = createSlice({
    name: "issueReport",
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(issueReport.pending, (state:any) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(issueReport.fulfilled, (state:any, action:any) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(issueReport.rejected, (state:any, action:any) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default issueReportSlice.reducer;

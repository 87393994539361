import { Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Preloader from "./components/preloader";
import { IRoute, getRoutes } from "./utils/routes";
import { ThemeProvider } from "@mui/material";
import { useAppSelector } from "./redux/hooks";
import getTheme from "./utils/theme";
import AdminProtection from "./utils/AdminProtection";
const ErrorNotFound = lazy(() => import("./pages/error-404"));
import  TokenProtection from "./utils/ProtectedRoute";
import  SidebarLayout from "./layouts/layout-02";

const Router = () => {
    const palette = useAppSelector((state) => state.theme.theme);
    return (
        <ThemeProvider theme={getTheme(palette as any)}>
            <BrowserRouter>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route element={<TokenProtection />}>
                            {mapRoutes(getRoutes("tokenProtected"))}
                        </Route>
                        <Route element={<AdminProtection />}>
                            {mapRoutes(getRoutes("adminProtected"))}
                        </Route>
                        {mapRoutes(getRoutes("unprotected"))}
                        <Route path="*" element={<ErrorNotFound />} />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    );
};
const mapRoutes = (routes: IRoute[]): JSX.Element[] =>
    routes.map((route, key) => (
        <Route
            key={key}
            path={route.route}
            element={
                route.sidebar ? (
                    <SidebarLayout>
                        <route.component />
                    </SidebarLayout>
                ) : (
                    <route.component />
                )
            }
        />
    ));
export default Router;

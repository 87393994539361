/* eslint-disable */
import { dataFixer, removeData, addYear } from "./data-helper";
import { ensureArray, isEmpty } from "./formater-helper";
const months: any = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export function dateToSeconds(dateString: any) {
    const date = new Date(dateString);
    const seconds = date.getTime() / 1000;
    return seconds * 1000;
}
const getPreviousMonth = (item: any) => {
    const currentMonth: any = months.find(
        (month: any) => item[month] !== "undefined NaN"
    );
    if (!currentMonth) return null; // If current month not found or has invalid data

    const currentMonthIndex = months.indexOf(currentMonth);
    const prevMonthIndex = (currentMonthIndex - 1 + 12) % 12; // Handling January case
    const prevMonth = months[prevMonthIndex];

    return parseInt(item[prevMonth]);
};
export const keywordGraph = (data: any[]) => {
    return data
        .filter((el) => el.row.startsWith("pos"))
        .map((item) => {
            const { row } = item;
            const prevMonthValue = getPreviousMonth(item);
            return {
                x: row.replace("pos_", "").replace("_", "-"),
                y: prevMonthValue,
            };
        });
};
export const referringGraph = (data: any[]) => {
    const dateshorter = (date: any) => {
        const parsedDate = new Date(date);
        return parsedDate.toLocaleString("default", {
            month: "short",
            year: "2-digit",
        });
    };

    const sortDataByDate = (data: any[]) => {
        return data.sort((a: any, b: any) => {
            const dateA: any = new Date(a.dates);
            const dateB: any = new Date(b.dates);
            return dateA - dateB;
        });
    };

    const series = [
        {
            name: "Total",
            data: sortDataByDate(data)?.map((item: any) => {
                return [
                    dateshorter(item.dates),
                    Number(item.referring_domains),
                ];
            }),
        },
        {
            name: "New",
            data: sortDataByDate(data)?.map((item: any) => {
                return [
                    dateshorter(item.dates),
                    Number(item.new_referring_domains),
                ];
            }),
        },
        {
            name: "Lost",
            data: sortDataByDate(data)?.map((item: any) => {
                return [
                    dateshorter(item.dates),
                    Number(item.lost_referring_domains),
                ];
            }),
        },
    ];

    return series;
};

export const backlinkGraph = (data: any[]) => {
    const dateshorter = (date: any) => {
        const parsedDate = new Date(date);

        return parsedDate.toLocaleString("default", {
            month: "short",
            year: "2-digit",
        });
    };
    const sortDataByDate = (data: any[]) => {
        return data.sort((a: any, b: any) => {
            const dateA: any = new Date(a.dates);
            const dateB: any = new Date(b.dates);
            return dateA - dateB;
        });
    };
    return [
        {
            name: "Backlinks",
            data: sortDataByDate(data)?.map((item: any) => {
                return [dateshorter(item.dates), Number(item.backlinks)];
            }),
            type: "area",
        },
        {
            name: "Broken Backlinks",
            data: sortDataByDate(data)?.map((item: any) => {
                return [dateshorter(item.dates), Number(item.broken_backlinks)];
            }),
            type: "area",
        },
        {
            name: "New Backlinks",
            data: sortDataByDate(data)?.map((item: any) => {
                return [dateshorter(item.dates), Number(item.new_backlinks)];
            }),
            type: "area",
        },
        {
            name: "Lost Backlinks",
            data: sortDataByDate(data)?.map((item: any) => {
                return [dateshorter(item.dates), Number(item.lost_backlinks)];
            }),
            type: "area",
        },
    ];
};
export const backlinkPie = (datas: any[]) => {
    const data2: any[] = datas[1];
    let val = 0;
    for (let i = 0; i < data2?.length; i++) {
        val += Number(data2[i]?.backlinks);
    }
    const data: any[] = [
        ...datas[0],
        {
            names: "dofollow",
            "sum(descriptions)":
                val -
                datas[0]?.reduce(
                    (sum: any, item: any) =>
                        sum + parseInt(item["sum(descriptions)"], 10),
                    0
                ),
        },
    ];
    const colors: any[] = [];
    const classic: any = {
        success: "#10b759",
        info: "#00b8d4",
        warning: "#ffc107",
        danger: "#dc3545",
        pink: "#f10075",
        orange: "#fd7e14",
        cyan: "#17a2b8",
        teal: "#00cccc",
        indigo: "#5b47fb",
        litecoin: "#325a98",
        lightblue: "#a5d7fd",
        brand2: "#042893",
        shuttle: "#5c6c7f",
        science: "#063cdd",
        facebook: "#4064ac",
        twitter: "#00a7e6",
        shuttle2: "#566476",
        lilac: "#f8f9fc",
        catskill: "#eef0f7",
        gulf: "#031a61",
        river: "#475362",
        zircon: "#f3f8ff",
        clay: "#283143",
        clay2: "#232b3b",
        shaft: "#292929",
        cod: "#111111",
        silver: "#cdcdcd",
    };
    for (const key in classic) {
        colors.push({ variant: key, color: classic[key] });
    }
    return (
        data?.map((item: any, ind: any) => ({
            ...item,
            "sum(descriptions)": parseInt(item["sum(descriptions)"], 10),
            no: ind + 1,
            color: colors?.[ind],
            names: item?.names?.toUpperCase(),
            percentage: (
                (parseInt(item["sum(descriptions)"], 10) /
                    data?.reduce(
                        (sum, item) =>
                            sum + parseInt(item["sum(descriptions)"], 10),
                        0
                    )) *
                100
            ).toFixed(2),
        })) || []
    ).sort((a, b) => b[`sum(descriptions)`] - a[`sum(descriptions)`]);
};

////////////// zaghum graph helper functions start ////////////

const commonOptions = {
    tooltip: {
        enabled: true,
    },
    stroke: {
        curve: "smooth",
        width: 2,
    },
    fill: {
        opacity: 1,
    },
    yaxis: {
        show: false,
    },
    xaxis: {
        show: true,
    },
    colors: ["#17E4A0"],
    title: {
        text: undefined,
    },
    subtitle: {
        text: undefined,
    },
};
export const sortByMonth = (data: any[]): any[] => {
    return data.slice().sort((a: any, b: any) => {
        const dateA: any = new Date(a.dates);
        const dateB: any = new Date(b.dates);
        return dateA - dateB;
    });
};
export const f = new Intl.NumberFormat("en-us", {
    notation: "compact",
});
export const convertToMillionThousands = (number: any) => {
    if (number >= 1000000) {
        const million = Math.floor(number / 1000000);
        const remainder = number % 1000000;
        return `${million}M`;
    } else if (number >= 1000) {
        const thousand = Math.floor(number / 1000);
        return `${thousand}K`;
    } else {
        const roundeFigure = Math.round(number);
        return roundeFigure.toString();
    }
};

const getCurrentAndPreviousMonth = (data: any) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonthIndex = new Date().getMonth();
    const previousMonthIndex = (currentMonthIndex + 11) % 12;
    const secoundPreviousMonthIndex = (currentMonthIndex + 10) % 12;
    const previousMonth = new Date(
        currentYear,
        previousMonthIndex
    ).toLocaleString("en-US", {
        month: "short",
    });
    const secoundPreviousMonth = new Date(
        currentYear,
        secoundPreviousMonthIndex
    ).toLocaleString("en-US", {
        month: "short",
    });
    const previousMonthData = data?.[0]?.[previousMonth];
    const secoundPreviousMonthData = data?.[0]?.[secoundPreviousMonth];
    return {
        previousMonth: {
            name: previousMonth,
            value: previousMonthData,
        },
        secoundPreviousMonth: {
            name: secoundPreviousMonth,
            value: secoundPreviousMonthData,
        },
    };
};

export const getGrowthAndPercentage = (apiData: any) => {
    const result: any = {
        growth: "",
        percentageValue: 0,
    };

    const { previousMonth, secoundPreviousMonth } = getCurrentAndPreviousMonth(
        removeData(apiData)
    );
    if (previousMonth.value != 0 && secoundPreviousMonth.value != 0) {
        const percentageDifferenceSS =
            ((previousMonth.value - secoundPreviousMonth.value) /
                Math.abs(secoundPreviousMonth.value)) *
            100;
        result.percentageValue = percentageDifferenceSS.toFixed(1) + "%";

        if (previousMonth.value > secoundPreviousMonth.value) {
            result.growth = "up";
        } else {
            result.growth = "down";
        }
    } else {
        result.percentageValue = "";
    }

    return result;
};

export const getGrowthAndPercentageSmallChart = (apiData: any) => {
    const data = dataFixer(apiData);

    const result: any = {
        growthDR: "",
        growthSS: "",
        growthTB: "",
        growthRD: "",
        percentageValueDR: 0,
        percentageValueSS: 0,
        percentageValueTB: 0,
        percentageValueRD: 0,
    };
    const previousDR = data[0]?.rank;
    const scoundPreviousDR = data[1]?.rank;
    const previousTB = data[0]?.backlinks;
    const scoundPreviousTB = data[1]?.backlinks;
    const previousRD = data[0]?.referring_domains;
    const scoundPreviousRD = data[1]?.referring_domains;
    if (previousDR > scoundPreviousDR || previousDR === scoundPreviousDR) {
        const percentageDifferenceDR =
            ((previousDR - scoundPreviousDR) / Math.abs(scoundPreviousDR)) *
            100;
        result.percentageValueDR = percentageDifferenceDR.toFixed(1) + "%";
        result.growthDR = "up";
    } else {
        result.growthDR = "down";
    }

    if (previousTB > scoundPreviousTB) {
        const percentageDifferenceTB =
            ((previousTB - scoundPreviousTB) / Math.abs(scoundPreviousTB)) *
            100;
        result.percentageValueTB = percentageDifferenceTB.toFixed(1) + "%";
        result.growthTB = "up";
    } else {
        result.growthTB = "down";
    }

    if (previousRD > scoundPreviousRD) {
        const percentageDifferenceRD =
            ((previousRD - scoundPreviousRD) / Math.abs(scoundPreviousRD)) *
            100;
        result.percentageValueRD = percentageDifferenceRD.toFixed(1) + "%";
        result.growthRD = "up";
    } else {
        result.growthRD = "down";
    }
    return result;
};
export const smallGraphs = (apiData: any) => {
    return [
        {
            id: 1,
            title: "DOMAIN RANK",
            rate: f.format(Number(dataFixer(apiData?.[0])?.[0]?.rank)),
            // rate: convertToMillionThousands(dataFixer(apiData?.[0])?.[0]?.rank),
            change: {
                percentage: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.percentageValueDR,
                growth: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.growthDR,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[0]))?.map(
                        (item: any) => item.dates
                    ),
                },
                series: [
                    {
                        name: "Doamin Rank",
                        data: sortByMonth(dataFixer(apiData?.[0]))?.map(
                            (item: any) => item?.rank
                        ),
                    },
                ],
            },
        },
        {
            id: 2,
            title: "SPAM SCORE",
            rate: f.format(Number(dataFixer(apiData?.[10])[0]?.descriptions)),

            // rate: convertToMillionThousands(
            //     dataFixer(apiData?.[10])[0]?.descriptions
            // ),
            change: {
                percentage: getGrowthAndPercentageSmallChart(apiData?.[10])
                    ?.percentageValueSS,
                growth: getGrowthAndPercentageSmallChart(apiData?.[10])
                    ?.growthSS,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[10]))?.map(
                        (item: any) => item?.dates
                    ),
                },
                series: [
                    {
                        name: "Spam Score",
                        data: sortByMonth(dataFixer(apiData?.[10]))?.map(
                            (item: any) => item?.descriptions
                        ),
                    },
                ],
            },
        },
        {
            id: 3,
            title: "TOTAL BACKLINK",
            rate: f.format(Number(dataFixer(apiData?.[0])[0]?.backlinks)),
            // rate: convertToMillionThousands(
            //     dataFixer(apiData?.[0])[0]?.backlinks
            // ),
            change: {
                percentage: getGrowthAndPercentageSmallChart(apiData?.[0])
                    .percentageValueTB,
                growth: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.growthTB,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[0]))?.map(
                        (item: any) => item?.dates
                    ),
                },
                series: [
                    {
                        name: "Total Backlinks",
                        data: sortByMonth(dataFixer(apiData?.[0]))?.map(
                            (item: any) => item?.backlinks
                        ),
                    },
                ],
            },
        },
        {
            id: 4,
            title: "REFERRING DOMAIN",
            // rate: convertToMillionThousands(
            //     dataFixer(apiData?.[0])[0]?.referring_domains
            // ),
            rate: f.format(
                Number(dataFixer(apiData?.[0])[0]?.referring_domains)
            ),
            change: {
                percentage: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.percentageValueRD,
                growth: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.growthRD,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[0]))?.map(
                        (item: any) => item?.dates
                    ),
                },
                series: [
                    {
                        name: "Referring Domain",
                        data: sortByMonth(dataFixer(apiData?.[0]))?.map(
                            (item: any) => item?.referring_domains
                        ),
                    },
                ],
            },
        },
        {
            id: 5,
            title: "ORGANIC KEYWORD",
            rate: convertToMillionThousands(
                getCurrentAndPreviousMonth(removeData(apiData?.[12]))
                    ?.previousMonth.value
            ),
            change: {
                percentage: getGrowthAndPercentage(apiData?.[12])
                    ?.percentageValue,
                growth: getGrowthAndPercentage(apiData?.[12])?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: removeData(apiData?.[12])?.map((item: any) =>
                        Object.keys(item)
                    )[0],
                },
                series: [
                    {
                        name: "Organic Keyword",
                        data: removeData(apiData?.[12])?.map((item: any) =>
                            Object.values(item)
                        )[0],
                    },
                ],
            },
        },
        {
            id: 6,
            title: "PAID KEYWORD",
            rate: convertToMillionThousands(
                getCurrentAndPreviousMonth(removeData(apiData?.[13]))
                    .previousMonth.value
            ),
            change: {
                percentage: getGrowthAndPercentage(apiData?.[13])
                    ?.percentageValue,
                growth: getGrowthAndPercentage(apiData?.[13])?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: removeData(apiData?.[13])?.map((item: any) =>
                        Object.keys(item)
                    )[0],
                },
                series: [
                    {
                        name: "Paid Keyword",
                        data: removeData(apiData?.[13])?.map((item: any) =>
                            Object.values(item)
                        )[0],
                    },
                ],
            },
        },
        {
            id: 7,
            title: "ORGANIC TRAFFIC",
            rate: convertToMillionThousands(
                getCurrentAndPreviousMonth(removeData(apiData?.[14]))
                    ?.previousMonth.value
            ),
            change: {
                percentage: getGrowthAndPercentage(apiData?.[14])
                    ?.percentageValue,
                growth: getGrowthAndPercentage(apiData?.[14])?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: removeData(apiData?.[14])?.map((item: any) =>
                        Object.keys(item)
                    )[0],
                },
                series: [
                    {
                        name: "Organic Trafic",
                        data: removeData(apiData?.[14])?.map((item: any) =>
                            Object.values(item)
                        )[0],
                    },
                ],
            },
        },
        {
            id: 8,
            title: "PAID TRAFFIC",
            rate: convertToMillionThousands(
                getCurrentAndPreviousMonth(removeData(apiData?.[15]))
                    ?.previousMonth.value
            ),
            change: {
                percentage: getGrowthAndPercentage(apiData?.[15])
                    ?.percentageValue,
                growth: getGrowthAndPercentage(apiData?.[15])?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: removeData(apiData?.[15])?.map((item: any) =>
                        Object.keys(item)
                    )[0],
                },
                series: [
                    {
                        name: "Paid Trafic",
                        data: removeData(apiData?.[15])?.map((item: any) =>
                            Object.values(item)
                        )[0],
                    },
                ],
            },
        },
    ];
};

export const smallGraphsRankBacklinkDashboard = (apiData: any) => {
    return [
        {
            id: 1,
            title: "DOMAIN RANK",
            rate: f.format(Number(dataFixer(apiData?.[0])?.[0]?.rank)),
            // rate: convertToMillionThousands(dataFixer(apiData?.[0])?.[0]?.rank),
            change: {
                percentage: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.percentageValueDR,
                growth: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.growthDR,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[0]))?.map(
                        (item: any) => item.dates
                    ),
                },
                series: [
                    {
                        name: "Doamin Rank",
                        data: sortByMonth(dataFixer(apiData?.[0]))?.map(
                            (item: any) => item?.rank
                        ),
                    },
                ],
            },
        },
        // {
        //     id: 2,
        //     title: "SPAM SCORE",
        //     rate: f.format(Number(dataFixer(apiData?.[10])[0]?.descriptions)),

        //     // rate: convertToMillionThousands(
        //     //     dataFixer(apiData?.[10])[0]?.descriptions
        //     // ),
        //     change: {
        //         percentage: getGrowthAndPercentageSmallChart(apiData?.[10])
        //             ?.percentageValueSS,
        //         growth: getGrowthAndPercentageSmallChart(apiData?.[10])
        //             ?.growthSS,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: sortByMonth(dataFixer(apiData?.[10]))?.map(
        //                 (item: any) => item?.dates
        //             ),
        //         },
        //         series: [
        //             {
        //                 name: "Spam Score",
        //                 data: sortByMonth(dataFixer(apiData?.[10]))?.map(
        //                     (item: any) => item?.descriptions
        //                 ),
        //             },
        //         ],
        //     },
        // },
        // {
        //     id: 3,
        //     title: "TOTAL BACKLINK",
        //     rate: f.format(Number(dataFixer(apiData?.[0])[0]?.backlinks)),
        //     // rate: convertToMillionThousands(
        //     //     dataFixer(apiData?.[0])[0]?.backlinks
        //     // ),
        //     change: {
        //         percentage: getGrowthAndPercentageSmallChart(apiData?.[0])
        //             .percentageValueTB,
        //         growth: getGrowthAndPercentageSmallChart(apiData?.[0])
        //             ?.growthTB,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: sortByMonth(dataFixer(apiData?.[0]))?.map(
        //                 (item: any) => item?.dates
        //             ),
        //         },
        //         series: [
        //             {
        //                 name: "Total Backlinks",
        //                 data: sortByMonth(dataFixer(apiData?.[0]))?.map(
        //                     (item: any) => item?.backlinks
        //                 ),
        //             },
        //         ],
        //     },
        // },
        {
            id: 4,
            title: "REFERRING DOMAIN",
            // rate: convertToMillionThousands(
            //     dataFixer(apiData?.[0])[0]?.referring_domains
            // ),
            rate: f.format(
                Number(dataFixer(apiData?.[0])[0]?.referring_domains)
            ),
            change: {
                percentage: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.percentageValueRD,
                growth: getGrowthAndPercentageSmallChart(apiData?.[0])
                    ?.growthRD,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[0]))?.map(
                        (item: any) => item?.dates
                    ),
                },
                series: [
                    {
                        name: "Referring Domain",
                        data: sortByMonth(dataFixer(apiData?.[0]))?.map(
                            (item: any) => item?.referring_domains
                        ),
                    },
                ],
            },
        },
        // {
        //     id: 5,
        //     title: "ORGANIC KEYWORD",
        //     rate: convertToMillionThousands(
        //         getCurrentAndPreviousMonth(removeData(apiData?.[12]))
        //             ?.previousMonth.value
        //     ),
        //     change: {
        //         percentage: getGrowthAndPercentage(apiData?.[12])
        //             ?.percentageValue,
        //         growth: getGrowthAndPercentage(apiData?.[12])?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: removeData(apiData?.[12])?.map((item: any) =>
        //                 Object.keys(item)
        //             )[0],
        //         },
        //         series: [
        //             {
        //                 name: "Organic Keyword",
        //                 data: removeData(apiData?.[12])?.map((item: any) =>
        //                     Object.values(item)
        //                 )[0],
        //             },
        //         ],
        //     },
        // },
        // {
        //     id: 6,
        //     title: "PAID KEYWORD",
        //     rate: convertToMillionThousands(
        //         getCurrentAndPreviousMonth(removeData(apiData?.[13]))
        //             .previousMonth.value
        //     ),
        //     change: {
        //         percentage: getGrowthAndPercentage(apiData?.[13])
        //             ?.percentageValue,
        //         growth: getGrowthAndPercentage(apiData?.[13])?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: removeData(apiData?.[13])?.map((item: any) =>
        //                 Object.keys(item)
        //             )[0],
        //         },
        //         series: [
        //             {
        //                 name: "Paid Keyword",
        //                 data: removeData(apiData?.[13])?.map((item: any) =>
        //                     Object.values(item)
        //                 )[0],
        //             },
        //         ],
        //     },
        // },
        // {
        //     id: 7,
        //     title: "ORGANIC TRAFFIC",
        //     rate: convertToMillionThousands(
        //         getCurrentAndPreviousMonth(removeData(apiData?.[14]))
        //             ?.previousMonth.value
        //     ),
        //     change: {
        //         percentage: getGrowthAndPercentage(apiData?.[14])
        //             ?.percentageValue,
        //         growth: getGrowthAndPercentage(apiData?.[14])?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: removeData(apiData?.[14])?.map((item: any) =>
        //                 Object.keys(item)
        //             )[0],
        //         },
        //         series: [
        //             {
        //                 name: "Organic Trafic",
        //                 data: removeData(apiData?.[14])?.map((item: any) =>
        //                     Object.values(item)
        //                 )[0],
        //             },
        //         ],
        //     },
        // },
        // {
        //     id: 8,
        //     title: "PAID TRAFFIC",
        //     rate: convertToMillionThousands(
        //         getCurrentAndPreviousMonth(removeData(apiData?.[15]))
        //             ?.previousMonth.value
        //     ),
        //     change: {
        //         percentage: getGrowthAndPercentage(apiData?.[15])
        //             ?.percentageValue,
        //         growth: getGrowthAndPercentage(apiData?.[15])?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: removeData(apiData?.[15])?.map((item: any) =>
        //                 Object.keys(item)
        //             )[0],
        //         },
        //         series: [
        //             {
        //                 name: "Paid Trafic",
        //                 data: removeData(apiData?.[15])?.map((item: any) =>
        //                     Object.values(item)
        //                 )[0],
        //             },
        //         ],
        //     },
        // },
    ];
};

export const getPerBaklinksGraph = (apiData: any) => {
    if (!apiData || apiData.length === 0) {
        return {
            growth: "N/A",
            percentageValue: "N/A",
        };
    }
    const result: any = {
        growth: "",
        percentageValue: 0,
    };
    if (apiData[0]?.domain_rank != 0 && apiData[1]?.domain_rank != 0) {
        const percentageDifferenceSS =
            ((apiData[0]?.domain_rank - apiData[1]?.domain_rank) /
                Math.abs(apiData[1]?.domain_rank)) *
            100;
        result.percentageValue = Math.round(percentageDifferenceSS) + "%";

        if (apiData[0]?.domain_rank > apiData[1]?.domain_rank) {
            result.growth = "up";
        } else {
            result.growth = "down";
        }
    } else {
        result.percentageValue = "";
    }

    return result;
};
export const refDoamin = (apiData: any) => {
    if (!apiData || apiData.length === 0) {
        return {
            growth: "N/A",
            percentageValue: "N/A",
        };
    }
    const result: any = {
        growth: "",
        percentageValue: 0,
    };
    if (
        apiData[0]?.referring_domain != 0 &&
        apiData[1]?.referring_domain != 0
    ) {
        const percentageDifferenceSS =
            ((apiData[0]?.referring_domain - apiData[1]?.referring_domain) /
                Math.abs(apiData[1]?.referring_domain)) *
            100;
        result.percentageValue = Math.round(percentageDifferenceSS) + "%";
        if (apiData[0]?.referring_domain > apiData[1]?.referring_domain) {
            result.growth = "up";
        } else {
            result.growth = "down";
        }
    } else {
        result.percentageValue = "";
    }
    return result;
};
export const tBacklinks = (apiData: any) => {
    if (!apiData || apiData.length === 0) {
        return {
            growth: "N/A",
            percentageValue: "N/A",
        };
    }
    const result: any = {
        growth: "",
        percentageValue: 0,
    };
    if (apiData[0]?.total_backlinks != 0 && apiData[1]?.total_backlinks != 0) {
        const percentageDifferenceSS =
            ((apiData[0]?.total_backlinks - apiData[1]?.total_backlinks) /
                Math.abs(apiData[1]?.total_backlinks)) *
            100;
        result.percentageValue = Math.round(percentageDifferenceSS) + "%";
        if (apiData[0]?.total_backlinks > apiData[1]?.total_backlinks) {
            result.growth = "up";
        } else {
            result.growth = "down";
        }
    } else {
        result.percentageValue = "";
    }
    return result;
};
export const backlinksSmallGraphs = (apiData: any) => {
    return [
        // {
        //     id: 1,
        //     title: "DOMAIN RANK",
        //     rate: f.format(
        //         Number(dataFixer(apiData?.[6])?.[4]?.domain_rank ?? 0) / 10
        //     ),
        //     change: {
        //         percentage: getPerBaklinksGraph(dataFixer(apiData?.[6] ?? 0))
        //             ?.percentageValue,
        //         growth: getPerBaklinksGraph(dataFixer(apiData?.[6] ?? 0))
        //             ?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: sortByMonth(dataFixer(apiData?.[6]))?.map(
        //                 (item: any) => item?.month_year
        //             ),
        //         },
        //         series: [
        //             {
        //                 name: "Domain Rank",
        //                 data: sortByMonth(dataFixer(apiData?.[6]))?.map(
        //                     (item: any) => Math.round(item?.domain_rank / 10)
        //                 ),
        //             },
        //         ],
        //     },
        // },
        {
            id: 2,
            title: "REFERRING DOMAIN",
            rate: f.format(
                Number(dataFixer(apiData?.[7])[0]?.referring_domain ?? 0)
            ),
            change: {
                percentage: refDoamin(dataFixer(apiData?.[7] ?? 0))
                    ?.percentageValue,
                growth: refDoamin(dataFixer(apiData?.[7] ?? 0))?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[7]))?.map(
                        (item: any) => item?.month_year
                    ),
                },
                series: [
                    {
                        name: "Refering domain",
                        data: sortByMonth(dataFixer(apiData?.[7]))?.map(
                            (item: any) => Math.round(item?.referring_domain)
                        ),
                    },
                ],
            },
        },
        // {
        //     id: 3,
        //     title: "TOTAL BACKLINKS",
        //     rate: convertToMillionThousands(
        //         dataFixer(apiData?.[12])[0]?.total_backlinks ?? 0
        //     ),
        //     change: {
        //         percentage: tBacklinks(dataFixer(apiData?.[12] ?? 0))
        //             ?.percentageValue,
        //         growth: tBacklinks(dataFixer(apiData?.[12] ?? 0))?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: sortByMonth(dataFixer(apiData?.[12]))?.map(
        //                 (item: any) => item?.month_year
        //             ),
        //         },
        //         series: [
        //             {
        //                 name: "Total backlinks",
        //                 data: sortByMonth(dataFixer(apiData?.[12]))?.map(
        //                     (item: any) => item?.total_backlinks
        //                 ),
        //             },
        //         ],
        //     },
        // },
    ];
};
export const rankdashboardbacklinksSmallGraphs = (apiData: any) => {
    console.log("apiData", dataFixer(apiData?.[0])[0]);
    return [
        {
            id: 1,
            title: "DOMAIN RANK",
            rate: f.format(
                Number(dataFixer(apiData?.[6])?.[4]?.domain_rank ?? 0) / 10
            ),
            change: {
                percentage: getPerBaklinksGraph(dataFixer(apiData?.[6] ?? 0))
                    ?.percentageValue,
                growth: getPerBaklinksGraph(dataFixer(apiData?.[6] ?? 0))
                    ?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[6]))?.map(
                        (item: any) => item?.month_year
                    ),
                },
                series: [
                    {
                        name: "Domain Rank",
                        data: sortByMonth(dataFixer(apiData?.[6]))?.map(
                            (item: any) => Math.round(item?.domain_rank / 10)
                        ),
                    },
                ],
            },
        },
        {
            id: 2,
            title: "REFERRING DOMAIN",
            rate: f.format(
                Number(dataFixer(apiData?.[0])[0]?.referring_domains ?? 0)
            ),
            change: {
                percentage: refDoamin(dataFixer(apiData?.[0] ?? 0))
                    ?.percentageValue,
                growth: refDoamin(dataFixer(apiData?.[0] ?? 0))?.growth,
                time: "than last week",
            },
            chart: {
                options: {
                    chart: {
                        id: "sparkline2",
                        sparkline: {
                            enabled: true,
                        },
                    },
                    ...commonOptions,
                    labels: sortByMonth(dataFixer(apiData?.[7]))?.map(
                        (item: any) => item?.month_year
                    ),
                },
                series: [
                    {
                        name: "Refering domain",
                        data: sortByMonth(dataFixer(apiData?.[7]))?.map(
                            (item: any) => Math.round(item?.referring_domain)
                        ),
                    },
                ],
            },
        },
        // {
        //     id: 3,
        //     title: "TOTAL BACKLINKS",
        //     rate: convertToMillionThousands(
        //         dataFixer(apiData?.[12])[0]?.total_backlinks ?? 0
        //     ),
        //     change: {
        //         percentage: tBacklinks(dataFixer(apiData?.[12] ?? 0))
        //             ?.percentageValue,
        //         growth: tBacklinks(dataFixer(apiData?.[12] ?? 0))?.growth,
        //         time: "than last week",
        //     },
        //     chart: {
        //         options: {
        //             chart: {
        //                 id: "sparkline2",
        //                 sparkline: {
        //                     enabled: true,
        //                 },
        //             },
        //             ...commonOptions,
        //             labels: sortByMonth(dataFixer(apiData?.[12]))?.map(
        //                 (item: any) => item?.month_year
        //             ),
        //         },
        //         series: [
        //             {
        //                 name: "Total backlinks",
        //                 data: sortByMonth(dataFixer(apiData?.[12]))?.map(
        //                     (item: any) => item?.total_backlinks
        //                 ),
        //             },
        //         ],
        //     },
        // },
    ];
};
export function convertJsonToArrays(jsonData: any): {
    categories: string[];
    data: number[];
} {
    if (typeof jsonData !== "object" || isEmpty(jsonData)) {
        return { categories: [], data: [] };
    }
    const categories: string[] = [];
    const data: number[] = [];
    for (const [date, value] of Object.entries(jsonData || {})) {
        categories.push(date);
        data.push(isNaN(Number(value)) ? 0 : (Number(value).toFixed(2) as any));
    }

    return { categories, data };
}
export const convertToApexChartData = (data: {
    [date: string]: {
        [category: string]: string;
    };
}): {
    categories: string[];
    series: {
        name: string;
        data: number[];
    }[];
} => {
    let categories: string[] = [];
    let series: {
        name: string;
        data: number[];
    }[] = [];

    for (const date in data) {
        categories.push(date);
        const values = data[date];

        for (const key in values) {
            if (!series.find((item) => item.name === key)) {
                series.push({
                    name: key,
                    data: [],
                });
            }

            const seriesIndex = series.findIndex((item) => item.name === key);
            series[seriesIndex].data.push(
                parseInt(values[key]).toFixed(2) as any
            );
        }
    }

    return { categories, series };
};
export function convertJsonToArrays2(inputData: any) {
    const seriesData: any[] = [];
    const categories: any[] = [];
    if (typeof inputData !== "object" || isEmpty(inputData)) {
        return { categories, seriesData };
    }
    for (const date in inputData) {
        if (inputData.hasOwnProperty(date)) {
            const dataPoint = inputData?.[date];
            const category = new Date(date)?.toLocaleDateString();
            categories?.push(category);
            for (const seriesName in dataPoint) {
                if (dataPoint.hasOwnProperty(seriesName)) {
                    const seriesValue = parseInt(
                        dataPoint?.[seriesName]
                    ).toFixed(2);
                    const existingSeries = seriesData?.find(
                        (series) => series.name === seriesName
                    );
                    if (existingSeries) {
                        existingSeries.data.push(seriesValue);
                    } else {
                        seriesData.push({
                            name: seriesName,
                            data: [seriesValue],
                        });
                    }
                }
            }
        }
    }
    return { categories, seriesData };
}
export function formatChartData(
    data: { name: string; data: Array<[string, number]> }[]
): { categories: string[]; series: { name: string; data: number[] }[] } {
    const dates = ensureArray(data)[0]?.data.map((item: any) => item[0]) || [];
    const series = ensureArray(data).map((item) => ({
        name: item.name,
        data: item.data.map((entry: any) => entry[1]),
    }));
    const formattedData = {
        categories: dates,
        series: series,
    };
    return formattedData;
}
export const formatChartData2 = (data: any) => {
    const outputData = [];
    for (const [domain, values] of Object.entries(data || {})) {
        const newEntry = {
            name: domain,
            x: (values as any)?.avg,
            y: (values as any)?.keywords,
            z: (values as any)?.vis,
            visible: true,
        };
        outputData.push(newEntry);
    }
    return outputData;
};

import menus from "@doar/shared/data/menu-aside";
import Header from "../header";
import {
    Divider,
    Glass,
    MainListItem,
    StyledListMenu,
    StyledMegaMenuWrapper,
    StyledSuperAside,
    StyledSuperMenuWrapper,
    SubListItem,
} from "./style";
import { useAppSelector } from "src/redux/hooks";
import {
    Backdrop,
    Box,
    Collapse,
    IconButton,
    List,
    Popover,
    PopoverVirtualElement,
    Popper,
    Skeleton,
    Slide,
    Stack,
} from "@mui/material";
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import {
    ChevronLeft,
    ChevronRight,
    ExternalLink,
    GitCommit,
    Icon,
} from "react-feather";
import { compareStr, ensureArray } from "src/helpers/formater-helper";
import { SectionTitle } from "@doar/components";
import { Link, useLocation, useNavigate } from "react-router-dom";
interface CategoryInfo {
    main: string;
    sub: string;
}
const Aisde = () => {
    const theme = useAppSelector((state) => state.theme.theme);
    const role = useAppSelector((state) => state.auth.user.role);
    const targetId = useAppSelector((state) => state.onPageProjectId.value);
    const [superMenu, setSuperMenu] = useState<
        {
            name: string;
            icon: Icon;
            route?: string | undefined;
            megamenu?: {
                name: string;
                icon?: Icon | undefined;
                route?: string | undefined;
                menu?:
                    | {
                          name: string;
                          route: string;
                      }[]
                    | undefined;
            }[];
        }[]
    >();
    const isDark = theme === "dark";
    const [open, setOpen] = useState<{
        top: {
            title: string;
            open: boolean;
            data: any[];
        };
        mid: {
            title: string;
        };
    }>({
        top: {
            title: "",
            open: false,
            data: [],
        },
        mid: { title: "" },
    });
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const project = useAppSelector((state) => state.RankTracker.projectInfo);
    function getCategoryInfo(): CategoryInfo | null {
        const activeRoute: string = location.pathname;
        if (superMenu) {
            for (const category of superMenu) {
                if (category?.megamenu?.length) {
                    for (const submenu of category?.megamenu) {
                        if (submenu.route === activeRoute) {
                            return {
                                main: category.name,
                                sub: submenu.name,
                            };
                        }
                        if (submenu.menu) {
                            for (const subsubmenu of submenu.menu) {
                                if (subsubmenu.route === activeRoute) {
                                    return {
                                        main: category.name,
                                        sub: submenu.name,
                                    };
                                }
                            }
                        }
                    }
                }
            }
        }
        return null;
    }
    const handleClose = () => {
        setOpen({
            top: {
                title: "",
                open: false,
                data: [],
            },
            mid: { title: "" },
        });
    };
    const handleNavigate = (route: string) => {
        navigate(route);
        handleClose();
    };
    const newRole = useMemo(() => role, [role]);
    useLayoutEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                if (newRole !== "Sub Admin") {
                    setSuperMenu(menus.filter((el) => el?.name !== "Admin"));
                } else {
                    setSuperMenu(menus);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 600);
            }
        };

        fetchData();
    }, [newRole]);
    const getDisabled = (route: string) => {
        if (compareStr(route, "Rank Tracker")) {
            console.log(route, "<<<<<<<<<<<<<<<,routeb ranker");
            if (!project?.id || !project?.project) {
                return true;
            } else {
                return false;
            }
            return true;
        } else if (compareStr(route, "Website Audit")) {
            console.log(route, "<<<<<<<condition true ----->");
            return !targetId && targetId !== undefined;
        } else {
            return false;
        }
    };
    const getOnpageDisable = (route: any) => {
        console.log(route, "<<<<-------------\\routeee");
        if (compareStr(route, "Website Audit")) {
            return !targetId && targetId !== undefined;
        }
        return false;
    };
    return (
        <Box
            display={"flex"}
            justifyContent={"start"}
            alignItems={"start"}
            minWidth={250}
            maxWidth={250}
            position={"absolute"}
            height={"100dvh"}
            sx={{
                overflow: "hidden",
                top: 0,
                left: 0,
                bottom: 0,
            }}
        >
            <StyledSuperAside isFixed theme={theme} isExpand>
                <Header />
                <StyledSuperMenuWrapper>
                    {loading
                        ? [...Array(8)].map((_, i) => (
                              <React.Fragment key={i}>
                                  <Skeleton
                                      width={"100%"}
                                      animation="wave"
                                      height={50}
                                      sx={{ minHeight: "50px" }}
                                      variant="rounded"
                                  />
                                  {i !== 7 && <Divider isDark={isDark} />}
                              </React.Fragment>
                          ))
                        : superMenu?.map((main, key, mainArr) => (
                              <React.Fragment key={key}>
                                  {main?.route ? (
                                      <Link
                                          to={main?.route}
                                          onClick={handleClose}
                                          style={{
                                              width: "100%",
                                              border: "none",
                                              outline: "none",
                                          }}
                                      >
                                          <MainListItem
                                              isDark={isDark}
                                              isActive={
                                                  location.pathname ===
                                                      main.route ||
                                                  getCategoryInfo()?.main ===
                                                      main.name ||
                                                  (open.top.open &&
                                                      open.top.title ===
                                                          main.name)
                                              }
                                          >
                                              <Stack
                                                  direction={"row"}
                                                  justifyContent={"center"}
                                                  alignItems={"center"}
                                                  gap={1}
                                              >
                                                  <main.icon size={16} />{" "}
                                                  <p>{main.name}</p>
                                              </Stack>
                                              {!main.route && (
                                                  <ChevronRight size={18} />
                                              )}
                                          </MainListItem>
                                      </Link>
                                  ) : (
                                      <MainListItem
                                          isDark={isDark}
                                          isActive={
                                              location.pathname ===
                                                  main.route ||
                                              getCategoryInfo()?.main ===
                                                  main.name ||
                                              (open.top.open &&
                                                  open.top.title === main.name)
                                          }
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              main?.route
                                                  ? handleNavigate(main?.route)
                                                  : setOpen({
                                                        ...open,
                                                        top: {
                                                            title: main.name,
                                                            data: main?.megamenu as any,
                                                            open: !open.top
                                                                .open,
                                                        },
                                                    });
                                          }}
                                      >
                                          <Stack
                                              direction={"row"}
                                              justifyContent={"center"}
                                              alignItems={"center"}
                                              gap={1}
                                          >
                                              <main.icon size={16} />{" "}
                                              <p>{main.name}</p>
                                          </Stack>
                                          {!main.route && (
                                              <ChevronRight size={18} />
                                          )}
                                      </MainListItem>
                                  )}
                                  {mainArr?.length - 1 !== key && (
                                      <Divider isDark={isDark} />
                                  )}
                              </React.Fragment>
                          ))}
                </StyledSuperMenuWrapper>
                {open?.top?.open && <Glass onClick={handleClose} />}
            </StyledSuperAside>
            <StyledMegaMenuWrapper
                isDark={isDark}
                isOpen={open.top.open}
                onClick={(e) => e.stopPropagation()}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"start"}
                    gap={0.3}
                    sx={{
                        h5: {
                            whiteSpace: "nowrap",
                        },
                    }}
                    alignItems={"center"}
                >
                    <IconButton size="small" onClick={handleClose}>
                        <ChevronLeft
                            color={isDark ? "#fff" : "#000"}
                            size={16}
                        />
                    </IconButton>
                    <SectionTitle
                        title={open.top.title}
                        titleProps={{
                            fontSize: "100%",
                            fontWeight: 700,
                            lineHeight: "32px",
                        }}
                    />
                </Stack>
                {ensureArray(open?.top?.data)?.map(
                    (mega: any, subKey: number, arr: any) => {
                        return (
                            <Box
                                key={subKey}
                                sx={{
                                    cursor: getDisabled(open.top.title as any)
                                        ? "not-allowed"
                                        : "pointer",
                                }}
                                width={"100%"}
                            >
                                {mega?.route ? (
                                    <Link
                                        to={mega?.route}
                                        onClick={handleClose}
                                        style={{
                                            width: "100%",
                                            border: "none",
                                            outline: "none",
                                            pointerEvents:
                                                open.top.title ===
                                                    "Website Audit" &&
                                                mega?.name !==
                                                    "OnPage Projects" &&
                                                mega?.name !== "Add Audit Form"
                                                    ? "none"
                                                    : "all",
                                        }}
                                    >
                                        <SubListItem
                                            isDark={isDark}
                                            isActive={
                                                getCategoryInfo()?.sub ===
                                                    mega?.name ||
                                                open?.mid?.title === mega?.name
                                            }
                                            isDisabled={
                                                open.top.title ===
                                                    "Website Audit" &&
                                                !targetId &&
                                                targetId !== undefined &&
                                                mega?.name !==
                                                    "OnPage Projects" &&
                                                mega?.name !== "Add Audit Form"
                                            }
                                        >
                                            <Stack
                                                direction={"row"}
                                                justifyContent={"center"}
                                                alignItems={"center"}
                                                gap={1}
                                            >
                                                {mega?.route && !mega?.icon ? (
                                                    <ExternalLink size={15} />
                                                ) : (
                                                    <mega.icon size={15} />
                                                )}{" "}
                                                <p>{mega?.name}</p>
                                            </Stack>
                                            {!!mega?.menu?.length && (
                                                <ChevronRight
                                                    size={16}
                                                    style={{
                                                        transition:
                                                            "all .2s ease-in-out",
                                                        transform: `rotate(${
                                                            open.mid.title !==
                                                            mega?.name
                                                                ? 90
                                                                : 0
                                                        }deg)`,
                                                    }}
                                                />
                                            )}
                                        </SubListItem>
                                    </Link>
                                ) : (
                                    <SubListItem
                                        isDark={isDark}
                                        isActive={
                                            getCategoryInfo()?.sub ===
                                                mega?.name ||
                                            open?.mid?.title === mega?.name
                                        }
                                        // isDisabled={getDisabled(open.top.title)}
                                        isDisabled={getDisabled(
                                            open.top.title as any
                                        )}
                                        style={{
                                            border:
                                                open.top.title ===
                                                "Website Audit"
                                                    ? "2px solid yellow"
                                                    : "none",
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setOpen({
                                                ...open,
                                                mid: {
                                                    title:
                                                        open.mid.title ===
                                                        mega?.name
                                                            ? ""
                                                            : mega?.name,
                                                },
                                            });
                                        }}
                                    >
                                        <Stack
                                            direction={"row"}
                                            justifyContent={"center"}
                                            alignItems={"center"}
                                            gap={1}
                                        >
                                            {mega?.route && !mega?.icon ? (
                                                <ExternalLink size={15} />
                                            ) : (
                                                <mega.icon size={15} />
                                            )}{" "}
                                            <p>{mega?.name}</p>
                                        </Stack>
                                        {!!mega?.menu?.length && (
                                            <ChevronRight
                                                size={16}
                                                style={{
                                                    transition:
                                                        "all .2s ease-in-out",
                                                    transform: `rotate(${
                                                        open.mid.title !==
                                                        mega?.name
                                                            ? 90
                                                            : 0
                                                    }deg)`,
                                                }}
                                            />
                                        )}
                                    </SubListItem>
                                )}
                                <Collapse in={open?.mid?.title === mega?.name}>
                                    <Stack width={"100%"} py={1} gap={0.6}>
                                        {ensureArray(mega?.menu)?.map(
                                            (
                                                ele: any,
                                                inds: number,
                                                arrr: any
                                            ) => {
                                                return (
                                                    <React.Fragment key={inds}>
                                                        <StyledListMenu
                                                            to={ele?.route}
                                                            onClick={
                                                                handleClose
                                                            }
                                                            isActive={
                                                                location.pathname ===
                                                                ele?.route
                                                            }
                                                        >
                                                            <GitCommit
                                                                size={14}
                                                            />
                                                            <p>{ele?.name}</p>
                                                        </StyledListMenu>
                                                        {arrr?.length - 1 !==
                                                            inds && (
                                                            <Divider
                                                                isDark={isDark}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                );
                                            }
                                        )}
                                    </Stack>
                                </Collapse>
                                {arr?.length - 1 !== subKey && (
                                    <Divider isDark={isDark} />
                                )}
                            </Box>
                        );
                    }
                )}
            </StyledMegaMenuWrapper>
        </Box>
    );
};

export default Aisde;

import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
import { getRoleByNumber } from "../helpers/data-helper";

const AdminProtection = () => {
    const type = useAppSelector((state) => state.auth.user.u_type);
    const token = useAppSelector((state) => state.auth.token);
    const role = getRoleByNumber(type);
    if (role === "Sub Admin" && token) {
        return <Outlet />;
    } else {
        return <Navigate to="/" />;
    }
};
export default AdminProtection;

import { Navigate, Outlet } from "react-router-dom";
import { useAppSelector } from "src/redux/hooks";
const ProtectedRoute = () => {
    const token = useAppSelector((state) => state.auth.token);
    if (token) {
        return <Outlet />;
    } else {
        return <Navigate to="/login" />;
    }
};
export default ProtectedRoute;

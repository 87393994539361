import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "src/utils/authConfig";

export const getSerachConsoleData = createAsyncThunk(
    "data/details",
    async () => {
        try {
           
            const data = await API.get("/google_search_console.php");
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

export const getSerachConsoleFilterData = createAsyncThunk(
    "data/filterData",
    async (vals:any) => {
        try {
           
            const data = await API.post("/gsc_overview.php",vals);
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

const dataSlice = createSlice({
    name: "search Console",
    initialState: {
        data: [],
        status: false,
        error: null,
    } as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSerachConsoleData.pending, (state) => {
                state.status = true;
            })
            .addCase(getSerachConsoleData.fulfilled, (state, action) => {
                state.status = false;
                state.data = action.payload;
            })
            .addCase(getSerachConsoleData.rejected, (state, action) => {
                state.status = false;
            })
            .addCase(getSerachConsoleFilterData.pending, (state) => {
                state.status = true;
            })
            .addCase(getSerachConsoleFilterData.fulfilled, (state, action) => {
                state.status = false;
                state.data = action.payload;
            })
            .addCase(getSerachConsoleFilterData.rejected, (state, action) => {
                state.status = false;
            });
    },
});
export default dataSlice.reducer;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "src/utils/authConfig";
interface IFormValues {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
}
interface SignInFormValues {
    email: string;
    password: string;
}

export const packagesSet = createAsyncThunk(
    "data/packages",
    async (values: IFormValues) => {
        try {
            const data = await API.post("/admin_packages.php", values);
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const getPackages = createAsyncThunk("data/details", async () => {
    try {
        const data = await API.get("/admin_packages.php");
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
});
export const selectPackages = createAsyncThunk(
    "data/select",
    async (val: any) => {
        try {
            const data = await API.post("/packages.php", val);
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);
export const paypal = createAsyncThunk("data/paypal", async (val: any) => {
    try {
        const data = await API.post("/paypalnew/paypalPayment.php", val);
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
});
export const stripe = createAsyncThunk("data/stripe", async (val: any) => {
    try {
        const data = await API.get("/stripe/process.php", val);
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
});

export const createPackages = createAsyncThunk(
    "data/createPackages",
    async (val: any) => {
        try {
            const data = await API.post("/packagesnew.php", val);
            return data;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
);

const dataSlice = createSlice({
    name: "package",
    initialState: {
        packages: [],
        details: [],
        selectPackage: [],
        createPackage: [],
        formStatus: false,
        error: null,
    } as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(packagesSet.pending, (state) => {
                state.formStatus = false;
            })
            .addCase(packagesSet.fulfilled, (state, action) => {
                state.formStatus = true;
                state.packages = action.payload;
            })
            .addCase(packagesSet.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(getPackages.pending, (state) => {
                state.formStatus = false;
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.formStatus = true;
                state.details = action.payload;
            })
            .addCase(getPackages.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(selectPackages.pending, (state) => {
                state.formStatus = false;
            })
            .addCase(selectPackages.fulfilled, (state, action) => {
                state.formStatus = true;
                state.selectPackage = action.payload;
            })
            .addCase(selectPackages.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(createPackages.pending, (state) => {
                state.formStatus = false;
            })
            .addCase(createPackages.fulfilled, (state, action) => {
                state.formStatus = true;
                state.createPackage = action.payload;
            })
            .addCase(createPackages.rejected, (state, action) => {
                state.formStatus = false;
            });
    },
});
export default dataSlice.reducer;

/*eslint-disable*/

export const generateRandomURL = () => {
    const patterns = [
        { title: "domain/*", tooltip: `Domain only` },
        { title: "*.domain/*", tooltip: `Domain with Subdomain` },
        { title: "domain/path/*", tooltip: `Directory or path` },
        { title: "url", tooltip: "URL" },
    ];

    const randomPattern = patterns[Math.floor(Math.random() * patterns.length)];
    let randomURL = "";

    if (randomPattern.title === "domain/*") {
        randomURL = `${getRandomWord()}.com`;
    } else if (randomPattern.title === "*.domain/*") {
        randomURL = `${getRandomWord()}.${getRandomWord()}.com`;
    } else if (randomPattern.title === "domain/path/*") {
        randomURL = `${getRandomWord()}.com/${getRandomWord()}`;
    } else if (randomPattern.title === "url") {
        randomURL = `https://${getRandomWord()}.com`;
    }

    return randomURL;
};

function getRandomWord() {
    const words = [
        "google",
        "apple",
        "facebook",
        "stackoverflow",
        "github",
        "twitter",
        "youtube",
    ];

    return words[Math.floor(Math.random() * words.length)];
}

export const generateUUID = (): string => {
    const cryptoObj = window.crypto;
    const randomBytes = new Uint8Array(16);
    cryptoObj.getRandomValues(randomBytes);
    randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40;
    randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80;
    let uuid = "";
    for (let i = 0; i < 16; i++) {
        uuid += (randomBytes[i] < 16 ? "0" : "") + randomBytes[i].toString(16);
    }
    uuid =
        uuid.substr(0, 8) +
        "-" +
        uuid.substr(8, 4) +
        "-" +
        uuid.substr(12, 4) +
        "-" +
        uuid.substr(16, 4) +
        "-" +
        uuid.substr(20);
    return uuid;
};

type GenerateStringOptions = {
    numWords?: number;
    wordLengths?: [number, number];
    includeNumbers?: boolean;
    includeSpecialChars?: boolean;
    mixCharacters?: boolean;
    transformWords?: boolean;
    spacing?: number;
    duplicateCharacters?: boolean;
    nestedTransformations?: boolean;
    customTransformations?: ((str: string) => string)[];
    recursiveTransformations?: boolean;
    extremeComplexity?: boolean;
};

export function generateString(options?: GenerateStringOptions): string {
    const getRandomInt = (min: number, max: number): number => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const dummyWords: string[] = [
        "Lorem",
        "ipsum",
        "dolor",
        "sit",
        "amet",
        "consectetur",
        "adipiscing",
        "elit",
        "sed",
        "do",
        "eiusmod",
        "tempor",
        "incididunt",
        "ut",
        "labore",
        "et",
        "dolore",
        "magna",
        "aliqua",
        "Ut",
        "enim",
        "ad",
        "minim",
        "veniam",
        "quis",
        "nostrud",
        "exercitation",
        "ullamco",
        "laboris",
        "nisi",
        "ut",
        "aliquip",
        "ex",
        "ea",
        "commodo",
        "consequat",
    ];

    const {
        numWords = getRandomInt(5, 30),
        wordLengths = [10, 50],
        includeNumbers = false,
        includeSpecialChars = false,
        mixCharacters = false,
        transformWords = false,
        spacing = 1,
        duplicateCharacters = false,
        nestedTransformations = false,
        customTransformations = [],
        recursiveTransformations = false,
        extremeComplexity = false,
    } = options || {};

    const getRandomWord = (): string => {
        const wordList = includeNumbers
            ? dummyWords.concat(["123", "456", "789"])
            : dummyWords;

        let randomWord = wordList[Math.floor(Math.random() * wordList.length)];

        if (includeSpecialChars && Math.random() < 0.2) {
            const specialChars = [
                "!",
                "@",
                "#",
                "$",
                "%",
                "^",
                "&",
                "*",
                "_",
                "+",
                "-",
                "=",
                "?",
                "<",
                ">",
                "[",
                "]",
                "{",
                "}",
                "|",
                "/",
                "\\",
                ":",
                ";",
                ".",
                ",",
            ];
            const randomSpecialChar =
                specialChars[Math.floor(Math.random() * specialChars.length)];
            randomWord = `${randomSpecialChar}${randomWord}`;
        }

        if (mixCharacters && Math.random() < 0.5) {
            randomWord = randomWord
                .split("")
                .map((char, index) =>
                    Math.random() < 0.5
                        ? char.toUpperCase()
                        : char.toLowerCase()
                )
                .join("");
        }

        if (duplicateCharacters && Math.random() < 0.3) {
            randomWord = randomWord
                .split("")
                .map((char) => char.repeat(getRandomInt(1, 3)))
                .join("");
        }

        return randomWord;
    };

    const applyTransformations = (word: string, depth: number = 0): string => {
        if (transformWords) {
            const transformations = [
                (str: string) => str.toUpperCase(),
                (str: string) => str.toLowerCase(),
                (str: string) => str.split("").reverse().join(""),
                (str: string) => str.replace(/[aeiou]/g, ""),
                (str: string) =>
                    str
                        .split("")
                        .map((char) =>
                            Math.random() < 0.2 ? char.toUpperCase() : char
                        )
                        .join(""),
                (str: string) => {
                    if (nestedTransformations && Math.random() < 0.5) {
                        const nestedTransformation = applyTransformations(
                            str,
                            depth + 1
                        );
                        return `${str}(${nestedTransformation})`;
                    }
                    return str;
                },
            ];

            const selectedTransformations =
                customTransformations.length > 0
                    ? customTransformations
                    : transformations;

            let transformedWord = word;

            if (recursiveTransformations && depth < 3) {
                for (let i = 0; i < getRandomInt(1, 3); i++) {
                    const randomTransformation =
                        selectedTransformations[
                            Math.floor(
                                Math.random() * selectedTransformations.length
                            )
                        ];
                    transformedWord = randomTransformation(transformedWord);
                }
            } else {
                const randomTransformation =
                    selectedTransformations[
                        Math.floor(
                            Math.random() * selectedTransformations.length
                        )
                    ];
                transformedWord = randomTransformation(transformedWord);
            }

            return transformedWord;
        }

        return word;
    };

    const selectedWords: string[] = [];

    for (let i = 0; i < numWords; i++) {
        let word = getRandomWord();

        if (word.length < wordLengths[0]) {
            const diff = wordLengths[0] - word.length;
            word += "x".repeat(diff); // Append 'x' to meet the minimum length
        } else if (word.length > wordLengths[1]) {
            word = word.substring(0, wordLengths[1]); // Trim to meet the maximum length
        }

        word = applyTransformations(word);

        if (includeNumbers && Math.random() < 0.3) {
            const randomNumber = getRandomInt(0, 999);
            word = `${word}${randomNumber}`;
        }

        selectedWords.push(word);
    }

    const resultString: string = selectedWords.join(" ".repeat(spacing));

    if (extremeComplexity) {
        return resultString
            .split("")
            .map((char) => (Math.random() < 0.3 ? char.toUpperCase() : char))
            .reverse()
            .join("")
            .replace(/[aeiou]/g, "@")
            .split(" ")
            .map((word, index) =>
                index % 2 === 0 ? word.toUpperCase() : word.toLowerCase()
            )
            .join("***")
            .replace(/[^\w\s]/g, "");
    }

    return resultString;
}
export function generateBoolean(): boolean {
    const randomValue: number = Math.random();
    const userPreferenceFactor: number = Math.random();
    const deviceTypeFactor: number = Math.random();
    const isWeekend: boolean = new Date().getDay() % 6 === 0;

    const weights: Record<string, number> = {
        random: 0.1,
        userPreference: 0.3,
        deviceType: 0.2,
        weekend: 0.4,
    };

    const weightedSum: number =
        weights.random * randomValue +
        weights.userPreference * userPreferenceFactor +
        weights.deviceType * deviceTypeFactor +
        weights.weekend * (isWeekend ? 1 : 0);

    const complexThreshold: number = 0.6;
    const isComplex: boolean = weightedSum > complexThreshold;

    return isComplex ? Math.random() < 0.5 : randomValue < 0.5;
}

export function getColors(numColors: number): string[] {
    const colorArray: string[] = [
        "#008FFB",
        "#00E396",
        "#FEB019",
        "#E91E63",
        "#775DD0",
        "#219ebc",
        "#ffb703",
        "#606c38",
        "#bc6c25",
        "#c1121f",
        "#4a4e69",
        "#ef233c",
        "#2a9d8f",
        "#e9c46a",
        "#3a86ff",
        "#8338ec",
        "#354f52",
        "#3a0ca3",
        "#343a40",
        "#eae2b7",
        "#5f0f40",
        "#ffd6a5",
        "#8ac926",
        "#90ddf0",
        "#f2542d",
        "#7c6a0a",
        "#2364aa",
        "#acfcd9",
        "#ccc9dc",
        "#ffee32",
        "#acfcd9",
        "#9cc5a1",
        "#8ab0ab",
        "#d5896f",
        "#3e92cc",
        "#a69cac",
    ];

    const numColorsInArray = colorArray.length;

    if (numColors <= numColorsInArray) {
        return colorArray.slice(0, numColors);
    } else {
        const repeatedColors = Array.from(
            { length: Math.floor(numColors / numColorsInArray) },
            () => colorArray
        ).flat();
        const remainingColors = colorArray.slice(
            0,
            numColors % numColorsInArray
        );
        return repeatedColors.concat(remainingColors);
    }
}

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import API from "src/utils/authConfig";
import { ngrok } from "src/utils/tokenConfig";

export const rankedKeyword = createAsyncThunk(
    "data/google_ranked",
    async (val: any) => {
        try {
            const data = await API.post("/google_ranked_keywords.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const rankedKeyword_two = createAsyncThunk(
    "data/google_ranked_two",
    async (val: any) => {
        try {
            const data = await API.post("/google_ranked_keywords1.php",val);
            console.log(data,'data api hit------>')
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const competitorResearch = createAsyncThunk(
    "data/competitor_research",
    async (val: any) => {
        try {
            const data = await API.post("/google_serp_competitors.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const competitorSubdomain = createAsyncThunk(
    "data/subdomain",
    async (val: any) => {
        try {
            const data = await API.post("/google_subdomains.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const competitordomain = createAsyncThunk(
    "data/domain",
    async (val: any) => {
        try {
            const data = await API.post("/competitor.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const relevantPages = createAsyncThunk(
    "data/pages",
    async (val: any) => {
        try {
            const data = await API.post("/relevant_pages.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const ranked_keywords_sub = createAsyncThunk(
    "ranked_keywords_sub/submit",
    async (val: any) => {
        try {
            const data = await API.post("/rank_keywords_sub.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);

const dataSlice = createSlice({
    name: "google_ranked",
    initialState: {
        google_ranked: [],
        google_ranked_two:[],
        competitor: [],
        subSuggestion2: [],
        subdomain: [],
        competitorsdomain: [],
        relevantPage: [],
        domainGraph: [],
        keywords_sub: [],
        keywordDetails: "",
        formStatus: false,
        suggetionsStatus: "",
        keywodsSuggetionStatus: "",
        error: null,
        language: "",
    } as any,
    reducers: {
        addRankedkeywordDetails: (state, action) => {
            state.keywordDetails = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(rankedKeyword.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(rankedKeyword.fulfilled, (state, action) => {
                state.formStatus = false;
                state.google_ranked = action.payload;
            })
            .addCase(rankedKeyword.rejected, (state, action) => {
                state.formStatus = false;
            })
            
            .addCase(rankedKeyword_two.pending, (state) => {
                state.formStatus = true;
                state.error = null;
            })
            .addCase(rankedKeyword_two.fulfilled, (state, action) => {
                state.formStatus = false;
                state.google_ranked_two = action.payload;
            })
            .addCase(rankedKeyword_two.rejected, (state, action) => {
                state.formStatus = false;
                state.error = action.payload;
            })
            .addCase(competitorResearch.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(competitorResearch.fulfilled, (state, action) => {
                state.formStatus = false;
                state.competitor = action.payload;
            })
            .addCase(competitorResearch.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(competitorSubdomain.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(competitorSubdomain.fulfilled, (state, action) => {
                state.formStatus = false;
                state.subdomain = action.payload;
            })
            .addCase(competitorSubdomain.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(competitordomain.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(competitordomain.fulfilled, (state, action) => {
                state.formStatus = false;
                state.competitorsdomain = action.payload;
            })
            .addCase(competitordomain.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(relevantPages.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(relevantPages.fulfilled, (state, action) => {
                state.formStatus = false;
                state.relevantPage = action.payload;
            })
            .addCase(relevantPages.rejected, (state, action) => {
                state.formStatus = false;
            })
            .addCase(ranked_keywords_sub.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(ranked_keywords_sub.fulfilled, (state, action) => {
                state.formStatus = false;
                state.keywords_sub = action.payload;
            })
            .addCase(ranked_keywords_sub.rejected, (state, action) => {
                state.formStatus = false;
            });
    },
});
export const {addRankedkeywordDetails} = dataSlice.actions;
export default dataSlice.reducer;

import axios, { AxiosResponse } from "axios";
import { resetAllState, store } from "src/redux/store";
import toast from "react-hot-toast";
/*global BASE_URL */
/eslint no-undef: "error"/;

const API = axios.create({
    baseURL: "https://demo.optilinko.com/server",
    // withCredentials: true,
    timeout: 400000,
    headers: {
        Accept: "*",
        "Content-Type": "application/json",
    },
});

/**
 * api request interceptor
 * @param {Object} req request object
 * @returns
 */

const HandleRequest = async (req: any) => {
    const user: any = (await store?.getState()?.auth)
        ? store?.getState()?.auth
        : null;
    const validToken = user ? user?.token : null;
    if (!validToken) {
        window.location.href = "/login";
        resetAllState();
        return;
    }
    req.headers["Authorizationh"] = validToken;
    return req;
};

/**
 * api error interceptor
 * @param {Object} error error object
 * @returns
 */
const handleError = (error: any) => {
    return Promise.reject(error);
};

/**
 * api response interceptor
 * @param {Object} response response object
 * @returns
 */
const handleResponse = (response: any): AxiosResponse["data"] => {
    const {
        data: { code },
    } = response;
    if (Number(code) === 498) {
        window.location.href = "/login";
        resetAllState();
        toast.error("Invalid Token");
        return;
    }
    return Promise.resolve(response.data);
};

API.interceptors.request.use(HandleRequest);

API.interceptors.response.use(handleResponse, handleError);

export default API;

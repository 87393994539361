import styled, { device, css, themeGet } from "@doar/shared/styled";
import { Link } from "react-router-dom";
import { keyframes } from "styled-components";

interface IProps {
    $minimize: boolean;
    $maximize: boolean;
    $mdMinimize: boolean;
}

interface IVisibleProps {
    $show: boolean;
}

interface IAsideProps extends IProps, IVisibleProps {}
const minimizeCSS = css`
    width: auto;
    & + .content {
        margin-left: 60px;
    }
    .aside-body-inner {
        padding: 20px;
    }
    .aside-user {
        margin-bottom: 5px;
        .avatar {
            width: 26px;
            height: 26px;
            flex-shrink: 0;
            margin-left: -4px;
            &-initial {
                font-size: 10px;
            }
        }
        .aside-alerts {
            opacity: 0;
            visibility: hidden;
            position: fixed;
        }
    }
    .aside-loggedin-user {
        &-data {
            display: none;
        }
        &-nav {
            display: block;
            .nav {
                width: 100%;
                flex-wrap: nowrap;
            }
            svg {
                margin-right: 0;
            }
            span {
                position: fixed;
                opacity: 0;
                visibility: hidden;
                margin-left: 15px;
            }
        }
    }
    .aside-navbar {
        width: 100%;
        flex-wrap: nowrap;
        &-label {
            display: none;
        }
        &-link {
            svg {
                margin-right: 0;
            }
            span {
                position: fixed;
                opacity: 0;
                visibility: hidden;
                margin-left: 15px;
            }
            &:before {
                right: auto;
                left: -20px;
            }
            &:after {
                display: none;
            }
        }
        .submenu {
            position: relative;
            & > li {
                &:first-of-type {
                    margin-top: 10px;
                }
            }
            &:before {
                content: "";
                display: block;
                border-top: 1px solid ${themeGet("colors.border")};
                margin: 8px 0;
            }
        }
    }
`;

const maximizeCSS = css`
    .scrollbar {
        width: 240px;
    }
    .aside {
        &-body {
            width: 100%;
            display: block;
            &-inner {
                padding: 20px;
            }
        }
        &-user {
            .aside-alerts {
                opacity: 1;
                visibility: visible;
                position: relative;
            }
        }
        &-loggedin-user {
            &-nav {
                span {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
        &-navbar {
            &-link {
                span {
                    position: relative;
                    opacity: 1;
                    visibility: visible;
                }
                &:after {
                    display: block;
                }
            }
        }
    }
`;

export const StyledAside = styled.aside<IAsideProps>`
    width: 240px;
    height: 100%;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000;
    .scrollbar {
        .ps__thumb-x {
            display: none;
        }
    }
    ${({ $show }) =>
        $show &&
        css`
            transform: translateX(0);
        `}

    ${({ $minimize }) =>
        $minimize &&
        css`
            ${minimizeCSS}
        `}

    ${({ $mdMinimize, $show }) =>
        $mdMinimize &&
        !$show &&
        css`
            ${device.lgToXl} {
                ${minimizeCSS}
            }
        `}

    ${({ $mdMinimize, $show, $maximize }) =>
        $mdMinimize &&
        !$show &&
        !$maximize &&
        css`
            ${device.lgToXl} {
                .mega-submenu {
                    display: none;
                }
            }
        `}

    ${({ $maximize }) =>
        $maximize &&
        css`
            ${maximizeCSS}
        `}

    ${({ $minimize, $maximize }) =>
        $minimize &&
        !$maximize &&
        css`
            .mega-submenu {
                display: none;
            }
        `}

    ${device.large} {
        transform: translateX(0);
    }
`;

export const StyledBody = styled.div<IAsideProps>`
    height: 100%;
    padding-bottom: 70px;
    transition: all 0.3s;
    border-top: 1px solid ${themeGet("colors.border")};

    ${({ $minimize }) =>
        $minimize &&
        css`
            width: 60px;
            transition: none;
        `}
    ${({ $mdMinimize, $show }) =>
        $mdMinimize &&
        !$show &&
        css`
            ${device.lgToXl} {
                width: 60px;
                transition: none;
            }
        `}
    
    ${(props) =>
        props.theme.name !== "dark" &&
        css`
            background-color: #fff;
            border-right: 1px solid ${themeGet("colors.border")};
        `}
    ${(props) =>
        props.theme.name === "dark" &&
        css`
            background-color: ${themeGet("colors.gray900")};
        `}
`;

export const StyledBodyInner = styled.div`
    padding: 25px;
    position: absolute;
    inset: 0;
`;

export const StyledBackdrop = styled.div<IVisibleProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(28, 39, 60, 0.7);
    opacity: 0;
    z-index: 900;
    visibility: hidden;
    ${({ $show }) =>
        $show &&
        css`
            opacity: 1;
            visibility: visible;
        `}
    ${device.large} {
        display: none;
    }
`;
const Shake = keyframes`

	0%,
	100% {
		transform: translateX(0%);
		transform-origin: 20% 20%;
	}

	15% {
		transform: translateX(-2px) rotate(-.5deg);
	}

	30% {
		transform: translateX(2px) rotate(.5deg);
	}

	45% {
		transform: translateX(-2px) rotate(-1deg);
	}

	60% {
		transform: translateX(.8px) rotate(.8deg);
	}

	75% {
		transform: translateX(-1.5px) rotate(-.2deg);
	}

  
`;
export const StyledSuperAside = styled.div<{
    isFixed: boolean;
    theme: string;
    isExpand: boolean;
}>`
    transition: all 0.2s ease-in-out;
    height: 100%;
    width: 100%;
    background: ${({ theme }) => (theme === "dark" ? "#1C273C" : "#fff")};
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    z-index: 3;
    max-width: 250px !important;
    position: relative;
    .minimize-btn svg {
        margin-bottom: -3px;
        transform: rotate(180deg) !important;
    }
    .aside-logo {
        display: unset !important;
    }
    .short-icon {
        display: none !important;
    }
`;

export const StyledSuperMenuWrapper = styled.div`
    min-height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    height: calc(100vh - 60px);
    padding: 0.5rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    width: 100%;
`;
export const MainListItem = styled.menu<{
    isDark: boolean;
    isActive: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 4px;
    text-transform: capitalize;
    padding: 0.8rem 0.7rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0;
    border-radius: 4px;
    width: 100%;
    p {
        font-size: 13px;
    }
    background: ${({ isActive }) => (isActive ? "#0168FA" : "")};
    * {
        color: ${({ isActive, isDark }) =>
            isActive || isDark ? "#fff" : "#000"};
    }
    :hover {
        svg:first-child {
            animation: ${Shake} 0.6s ease 0s 1 normal forwards;
        }
        * {
            color: #fff;
        }
        background: rgba(1, 104, 250, 0.8);
    }
`;
export const Divider = styled.hr<{ isDark: boolean; isSub?: boolean }>`
    background-image: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        ${({ isDark }) => (!isDark ? "#333" : "#fff")},
        hsla(0, 0%, 100%, 0)
    );
    color: inherit;
    opacity: 0.25;
    border-top: none;
    min-height: ${({ isSub }) => (isSub ? 0.5 : 1)}px;
    margin: 0.3rem 0;
    width: 100%;
`;
export const StyledMegaMenuWrapper = styled.aside<{
    isDark: boolean;
    isOpen: boolean;
}>`
    height: calc(100vh - 60px);
    padding: 0.5rem;
    transition: all 0.2s ease-in-out;
    background: ${({ isDark }) => (isDark ? "#141C2B" : "#fdfffc")};
    border-left: 1px solid rgba(72, 94, 144, 0.16);
    min-width: 208px;
    max-width: 208px;
    position: absolute;
    top: 60px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    overflow-y: auto;
    right: ${({ isOpen }) => (isOpen ? 0 : -100)}%;
    z-index: 3;
    border-right: 1px solid rgba(72, 94, 144, 0.16);
    .MuiCollapse-vertical {
        width: 100% !important;
    }
`;

export const SubListItem = styled.div<{
    isDark: boolean;
    isActive: boolean;
    isDisabled?: boolean;
}>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 4px;
    text-transform: capitalize;
    padding: 0.6rem 0.5rem;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0;
    position: relative;
    border-radius: 4px;
    width: 100%;
    pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "all")};
    opacity: ${({ isDisabled }) => (isDisabled ? ".7" : "1")};
    p {
        font-size: 11px;
    }
    background: ${({ isActive }) => (isActive ? "#0168FA" : "")};
    * {
        color: ${({ isActive, isDark }) =>
            isActive || isDark ? "#fff" : "#000"};
    }

    :hover {
        svg:first-child {
            animation: ${Shake} 0.6s ease 0s 1 normal forwards;
        }
        * {
            color: #fff;
        }
        background: rgba(1, 104, 250, 0.8);
    }
`;

export const StyledListMenu = styled(Link)<{ isActive: boolean }>`
    padding: 0.2rem;
    width: 100%;
    cursor: pointer;
    display: flex;
    padding-left: 1.5rem;
    justify-content: start;
    align-items: center;
    grid-gap: 1rem;
    * {
        transition: all 0.2s ease-in-out;
    }
    p {
        position: relative;
        font-size: 13px;
    }
    p::before {
        content: " ";
        position: absolute;
        transition: all 0.2s ease-in-out;
        background-color: #0168fa;
        height: 2px;
        width: 0;
        inset: 0;
        top: 100%;
        bottom: -4px;
    }
    ${({ isActive }) =>
        isActive &&
        css`
            svg {
                color: #0168fa;
                transform: rotate(90deg);
            }
            p::before {
                width: 100%;
            }
        `}
    :hover {
        svg {
            color: #0168fa;
            transform: rotate(90deg);
        }
        p::before {
            width: 100%;
        }
    }
`;
export const Glass = styled.div`
    z-index: 1;
    position: absolute;
    inset: 60px 0px 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
`;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import API from "src/utils/authConfig";

export const fetchBacklinks = createAsyncThunk(
    "data/fetchBacklinks",
    async (newData:any) => {
        try {
            // const data = await API.post("/backlink_backlink_2.php", newData);
            const data = await API.post("/onload_backlink.php",newData);
            // const data = await API.post("/onload_backlink.php", values);
            // const data = await API.get("https://demo.optilinko.com/server/json.php");
 
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);

export const formSubmit = createAsyncThunk(
    "data/formSend",
    async (formData: any) => {
        try {
            const data = await API.post("/anchors.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const pageIntersectionForm = createAsyncThunk(
    "data/pageIntersection",
    async (formData: any) => {
        try {
            const data = await API.post("/page_intersection.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const domainIntersectionForm = createAsyncThunk(
    "data/domainIntersection",
    async (formData: any) => {
        try {
            const data = await API.post("/domain_intersection.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const timeSeriesSummery = createAsyncThunk(
    "data/timeSeriesSummery",
    async (formData: any) => {
        try {
            const data = await API.post("/timeseries_summary.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
        }
    }
);
export const timeSeries = createAsyncThunk(
    "data/timeSeries",
    async (formData: any) => {
        try {
            const data = await API.post("/naltimeseries.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);

export const domainSummeryGraph = createAsyncThunk(
    "data/summeryGraph",
    async (formData: any) => {
        try {
            const data = await API.post("/getstate_anchor.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const domainPageSummery = createAsyncThunk(
    "data/summery",
    async (formData: any) => {
        try {
            const data = await API.post("/domain_pages_summary.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const refferingNetwork = createAsyncThunk(
    "data/refferingNetwork",
    async (formData: any) => {
        try {
            const data = await API.post("/referring_networks.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const domainPage = createAsyncThunk(
    "data/domainPages",
    async (formData: any) => {
        try {
            const data = await API.post("/domain_pages.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const bulkRanks = createAsyncThunk(
    "data/bulkranks",
    async (formData: any) => {
        try {
            const data = await API.post("/bulk_ranks.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const bulkBacklinks = createAsyncThunk(
    "data/bulkBacklinks",
    async (formData: any) => {
        try {
            const data = await API.post("/bulk_backlink.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const bulkRefDoamin = createAsyncThunk(
    "data/bulkRefDoamin",
    async (formData: any) => {
        try {
            const data = await API.post("/bulk_refering_domain.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const bulkNLBacklinks = createAsyncThunk(
    "data/nkBacklinks",
    async (formData: any) => {
        try {
            const data = await API.post("/bulk_nalbacklinks.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const bulkNLReferrings = createAsyncThunk(
    "data/nkReferring",
    async (formData: any) => {
        try {
            const data = await API.post(
                "/bulk_nalrefering_domain.php",
                formData
            );
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const bulkSpamScores = createAsyncThunk(
    "data/bulkSpamScores",
    async (formData: any) => {
        try {
            const data = await API.post("/bulk_spam_score.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const referringDomains = createAsyncThunk(
    "data/referringDomain",
    async (formData: any) => {
        try {
            const data = await API.post("/referring_domains.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
export const competitors = createAsyncThunk(
    "data/competitors",
    async (formData: any) => {
        try {
            const data = await API.post("/competitors.php", formData);
            return data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
);
const dataSlice = createSlice({
    name: "data",
    initialState: {
        backlinksFormset:[],
        backlinks: [],
        anchor: [],
        pageIntersection: [],
        domainIntersection: [],
        summeryGraph: [],
        domainSummery: [],
        domainPages: [],
        bulkBacklink: [],
        timeSeriess: [],
        seriesSummery: [],
        bulkRank: [],
        bulkRefDoamins: [],
        refNetwork: [],
        competitor: [],
        referringDomain: [],
        bulkNLBacklink: [],
        bulkSpamScore: [],
        bulkNLReferring: [],
        status: "idle",
        formLoading: false,
        formStatus: "",
        error: null,
    } as any,
    reducers: {
        backlinksFormset: (state, action) => {
            state.backlinksFormset = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBacklinks.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(fetchBacklinks.fulfilled, (state, action) => {
                state.formLoading = false;
                state.backlinks = action.payload;
            })
            .addCase(fetchBacklinks.rejected, (state, action) => {
                state.formLoading = false;
                // state.error = action.error.message;
            })
            .addCase(formSubmit.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(formSubmit.fulfilled, (state, action) => {
                state.formLoading = false;
                state.anchor = action.payload;
            })
            .addCase(formSubmit.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(pageIntersectionForm.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(pageIntersectionForm.fulfilled, (state, action) => {
                state.formLoading = false;
                state.pageIntersection = action.payload;
            })
            .addCase(pageIntersectionForm.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(domainIntersectionForm.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(domainIntersectionForm.fulfilled, (state, action) => {
                state.formLoading = false;
                state.domainIntersection = action.payload;
            })
            .addCase(domainIntersectionForm.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(domainSummeryGraph.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(domainSummeryGraph.fulfilled, (state, action) => {
                state.formLoading = false;
                state.summeryGraph = action.payload;
            })
            .addCase(domainSummeryGraph.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(domainPageSummery.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(domainPageSummery.fulfilled, (state, action) => {
                state.formLoading = false;
                state.domainSummery = action.payload;
            })
            .addCase(domainPageSummery.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(timeSeries.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(timeSeries.fulfilled, (state, action) => {
                state.formLoading = false;
                state.timeSeriess = action.payload;
            })
            .addCase(timeSeries.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(timeSeriesSummery.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(timeSeriesSummery.fulfilled, (state, action) => {
                state.formLoading = false;
                state.seriesSummery = action.payload;
            })
            .addCase(timeSeriesSummery.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(domainPage.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(domainPage.fulfilled, (state, action) => {
                state.formLoading = false;
                state.domainPages = action.payload;
            })
            .addCase(domainPage.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(bulkRanks.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(bulkRanks.fulfilled, (state, action) => {
                state.formLoading = false;
                state.bulkRank = action.payload;
            })
            .addCase(bulkRanks.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(refferingNetwork.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(refferingNetwork.fulfilled, (state, action) => {
                state.formLoading = false;
                state.refNetwork = action.payload;
            })
            .addCase(refferingNetwork.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(competitors.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(competitors.fulfilled, (state, action) => {
                state.formLoading = false;
                state.competitor = action.payload;
            })
            .addCase(competitors.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(referringDomains.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(referringDomains.fulfilled, (state, action) => {
                state.formLoading = false;
                state.referringDomain = action.payload;
            })
            .addCase(referringDomains.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(bulkBacklinks.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(bulkBacklinks.fulfilled, (state, action) => {
                state.formLoading = false;
                state.bulkBacklink = action.payload;
            })
            .addCase(bulkBacklinks.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(bulkRefDoamin.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(bulkRefDoamin.fulfilled, (state, action) => {
                state.formLoading = false;
                state.bulkRefDoamins = action.payload;
            })
            .addCase(bulkRefDoamin.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(bulkNLBacklinks.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(bulkNLBacklinks.fulfilled, (state, action) => {
                state.formLoading = false;
                state.bulkNLBacklink = action.payload;
            })
            .addCase(bulkNLBacklinks.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(bulkNLReferrings.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(bulkNLReferrings.fulfilled, (state, action) => {
                state.formLoading = false;
                state.bulkNLReferring = action.payload;
            })
            .addCase(bulkNLReferrings.rejected, (state, action) => {
                state.formLoading = true;
            })
            .addCase(bulkSpamScores.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(bulkSpamScores.fulfilled, (state, action) => {
                state.formLoading = false;
                state.bulkSpamScore = action.payload;
            })
            .addCase(bulkSpamScores.rejected, (state, action) => {
                state.formLoading = false;
            });
    },
});
export const { backlinksFormset} = dataSlice.actions;
export default dataSlice.reducer;

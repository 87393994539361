import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import API from "src/utils/authConfig";


export const densityData = createAsyncThunk(
    "data/density",
    async (value:any) => {
       
        try {
            const {data} = await axios.post("https://demo.optilinko.com/server/onpage/onpage_keyword_density.php", {
                 tt_id: value 
            });
            console.log(data,"density api")
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const onPage = createAsyncThunk(
    "data/onpage",
    async () => {
        try {
            const response = await API.get("/onpage.php")
            return response.data;
        } catch (error) {
            console.log(error);
        }
    }
);

const dataSlice = createSlice({
    name: "onpage",
    initialState: {
        density: [],
        status: false,
        data: [],
        loading: false,
        error: null,
    } as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(densityData.pending, (state) => {
                state.status = true;
            })
            .addCase(densityData.fulfilled, (state, action) => {
                state.status = false;
                state.density = action.payload;
            })
            .addCase(densityData.rejected, (state, action) => {
                state.status = false;
            }) .addCase(onPage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(onPage.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(onPage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload  as any;
            });
    },
});

export default dataSlice.reducer;

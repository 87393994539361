/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback } from "react";
import { Menu, X, ArrowLeft, ChevronRight } from "react-feather";
import AsideLogo from "../../../components/aside-layout/logo";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { StyledHeader, StyledMenuBtn, StyledSidebarBtn } from "./style";
import { toggleFixedSidebar } from "src/redux/slices/ui";
import { MdOutlineWebhook } from "react-icons/md";
const Header = () => {
    const dispatch = useAppDispatch();
    const isFixed = useAppSelector((state) => state.ui.isFixed);
    const theme = useAppSelector((state) => state.theme.theme);
    const handleToogleSideBar = () => {
        dispatch(toggleFixedSidebar());
    };
    return (
        <StyledHeader $isFixed={isFixed} className="aside-header">
            <MdOutlineWebhook
                color="#0168FA"
                className="short-icon"
                size={25}
            />{" "}
            <AsideLogo />
            <StyledMenuBtn
                className="minimize-btn"
                onClick={handleToogleSideBar}
            >
                {/* <ChevronRight
                    size={20}
                    strokeWidth="3px"
                    color={theme === "dark" ? "#fff" : "#000"}
                /> */}
            </StyledMenuBtn>
        </StyledHeader>
    );
};

export default Header;

import { createGlobalStyle } from "styled-components";
import { device, themeGet } from "../styled";

import { IbmFontSans, InterUi, Rubik, FontAwesome } from "../fonts";

const GlobalStyle = createGlobalStyle`
    ${IbmFontSans};
    ${InterUi};
    ${Rubik};
	${FontAwesome};
    *, *:before, *:after {
        box-sizing: border-box;
    }
    html {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        scroll-behavior: smooth;
        height: 100%;
    }
    p,h1,h2,h3,h4,h5,h6{
        margin:.2rem 0;
    }
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    nav,
    section,
    summary {
        display: block;
    }
    audio,
    canvas,
    video {
        display: inline-block;
    }
    audio:not([controls]) {
        display: none;
        height: 0;
    }
    [hidden] {
        display: none;
    }
    html,
    button,
    input,
    select,
    textarea {
        font-family: ${themeGet("fonts.body")};
    }
    a:focus {
        outline: thin dotted;
    }
    a:active,
    a:hover {
        outline: 0;
    }
    b,
    strong {
        font-weight: bold;
    }
    blockquote,
    q {
        -webkit-hyphens: none;
        -moz-hyphens: none;
        -ms-hyphens: none;
        hyphens: none;
        quotes: none;
    }
    small {
        font-size: smaller;
    }
    sub,
    sup {
        font-size: 75%;
        line-height: 0;
        position: relative;
        vertical-align: baseline;
    }
    sup {
        top: -0.5em;
    }
    sub {
        bottom: -0.25em;
    }
    li > ul,
    li > ol {
        margin: 0;
    }
    img {
        -ms-interpolation-mode: bicubic;
        border: 0;
        vertical-align: middle;
    }
    svg:not(:root) {
        overflow: hidden;
    }
    figure {
        margin: 0;
    }
    form {
        margin: 0;
    }
    button,
    input,
    select,
    textarea {
        font-size: 100%;
        margin: 0;
        max-width: 100%;
        vertical-align: baseline;
    }

    button,
    input {
        line-height: normal;
    }

    button,
    html input[type="button"],
    input[type="reset"],
    input[type="submit"] {
        -webkit-appearance: button;
        -moz-appearance: button;
        appearance: button;
        cursor: pointer;
    }

    button[disabled],
    input[disabled] {
        cursor: default;
    }

    input[type="checkbox"],
    input[type="radio"] {
        padding: 0;
    }

    input[type="search"] {
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
        appearance: textfield;
        appearance: textfield;
        padding-right: 2px;
        width: 100%;
    }

    input[type="search"]::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
    }
    :disabled{
        cursor: not-allowed !important;
    }

    button:disabled,input:disabled{
        cursor:not-allowed !important;
    }
    button::-moz-focus-inner,
    input::-moz-focus-inner {
        border: 0;
        padding: 0;
    }
    textarea {
        overflow: auto;
        vertical-align: top;
    }
    img {
        max-width: 100%;
    }
    html {
        overflow: hidden;
        overflow-y: auto;
    }
    body {
        overflow: hidden;
        min-height: 100%;
        margin: 0;
        padding: 0;
        line-height: ${themeGet("lineHeights.body")};
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: ${themeGet("fonts.body")};
        font-weight: ${themeGet("fontWeights.body")};
        color: ${themeGet("colors.text")};
        font-size: ${themeGet("fontSize.body")};
        background: ${themeGet("colors.background")};
    }
    a {
        transition: ${themeGet("transition")};
        color: ${themeGet("colors.primary")};
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        font-family: ${themeGet("fonts.heading")};
        line-height: ${themeGet("lineHeights.heading")};
        color: ${themeGet("colors.heading")};
        font-weight: ${themeGet("fontWeights.heading")};
        margin: 0;
        margin-bottom: 8px;
    }
    h1,
    .h1 {
        font-size: ${themeGet("fontSize.h1.0")};
        ${device.small} {
            font-size: ${themeGet("fontSize.h1.1")};
        }
        ${device.medium} {
            font-size: ${themeGet("fontSize.h1.2")};
        }
        ${device.large} {
            font-size: ${themeGet("fontSize.h1.3")};
        }
    }
    h2,
    .h2 {
        font-size: ${themeGet("fontSize.h2.0")};
        ${device.medium} {
            font-size: ${themeGet("fontSize.h2.1")};
        }
        ${device.large} {
            font-size: ${themeGet("fontSize.h2.2")};
        }
    }
    h3,
    .h3 {
        font-size: ${themeGet("fontSize.h3.0")};
        ${device.large} {
            font-size: ${themeGet("fontSize.h3.1")};
        }
    }
    h4,
    .h4 {
        font-size: ${themeGet("fontSize.h4.0")};
        ${device.large} {
            font-size: ${themeGet("fontSize.h4.1")};
        }
    }
    h5,
    .h5 {
        font-size: ${themeGet("fontSize.h5.0")};
        ${device.large} {
            font-size: ${themeGet("fontSize.h5.1")};
        }
    }
    h6,
    .h6 {
        font-size: ${themeGet("fontSize.h6.0")};
        ${device.large} {
            font-size: ${themeGet("fontSize.h6.1")};
        }
    }
    p {
        font-family: ${themeGet("fonts.body")};
        color: ${themeGet("colors.text")};
		font-weight:  ${themeGet("fontWeight.body")};
        &:last-child {
            margin-bottom: 0;
        }
        &:only-child {
            margin-bottom: 0;
        }
    }
    input,
    select,
    textarea {
        background: transparent;
        border: 1px solid ${themeGet("colors.border")};
        transition: ${themeGet("transition")};
        color: ${themeGet("colors.text")};
        &:focus,
        &:active {
            outline: none;
            border-color: ${themeGet("colors.primary")};
        }
    }
   
    input,
    select,
    textarea {
        width: 100%;
        font-size: 14px;
    }
    input,
    select {
   
        padding: 0 15px;
    }
    .wrapper {
        position: relative;
    }
    .sr-only {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .link-overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-indent: -99999px;
    }
    table{
        border-collapse: collapse;
    }
    th {
        text-align: inherit;
    }
    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible;
    }
    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid ${themeGet("colors.border")};
    }
    .react-tooltip{
        z-index:9;
    }
    .__react_component_tooltip {
        font-size: 11px !important;
        padding: 3px 13px !important;
        width: max-content !important;
        background-color: ${themeGet("colors.black")} !important;
        &.place-top::after{
            border-top-color: ${themeGet("colors.black")} !important;
        }
    }
    .rdt_TableCol ~ div:not(&[data-column-id]){
        background-color: #f2f2f2 !important;
        background-color:rgba(1,104,250,.9) !important;
    }
    .rdt_TableHeadRow   div:not(&[data-column-id]){
        border-right: 1px solid rgba(72, 94, 144, 0.16) !important;
        background-color:rgba(1,104,250,.9) !important;
    }
	.error-clr{
        color:#DC3545;
    }   
    [data-tag="allowRowEvents"]:hover .serp_feature_hover{
        position: absolute;
        left: 6px;
        top: 0px;
        white-space: unset;
        overflow: unset;
        text-overflow: unset;
        padding: 7px;
        width: 100%;
        z-index: 1;
        border-radius: 4px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24;
        background-color:${themeGet("colors.light")};
        padding:1px;
        display:flex;
        justify-content:center;
        flex-wrap:wrap;
        grid-gap:4px;
    }
    [data-tag="allowRowEvents"]:hover .serp_feature_hover img{
        filter:grayscale(100%) !important;
        cursor:pointer;
    }
  [data-tag="allowRowEvents"]:hover .serp_feature_hover .serp_tooltip{
      display:unset !important;
    }
    .serp_tooltip{
        display:none;
    }
    [style*="z-index: 100000"] {
        display:none !important;
    }
    ::-webkit-scrollbar {
        width: 6px;
        height:6px
    }
    ::-webkit-scrollbar-track {
        background: rgba(125,136,154,.3);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(172, 172, 172);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(105, 105, 105);
    }
    .position-right {
        right: 0px;
        left: unset;
    }
    .container{
        max-width:100% !important;
        width:100% !important;
        min-width:100% !important;
    }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
       -webkit-appearance:none;
    }
    .cursor{
        cursor: pointer;
    }
    .infinite-scroll-component__outerdiv{
        width:100% !important;
    }
    input:-webkit-autofill,
     input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
}
    div[role="table"]{
     border-radius:6px !important;
     overflow:hidden !important;
    }
`;

export default GlobalStyle;

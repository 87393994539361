import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { countries } from "country-data";
import axios, { AxiosResponse } from "axios";
import API from "src/utils/authConfig";

export const selectCategories = createAsyncThunk(
    "data/selectCategories",
    async () => {
        try {
            const data = await API.get("/categories.php");
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);
export const categories = createAsyncThunk(
    "data/categories",
    async (formData: any) => {
        try {
            const data = await API.post("/categories_for_domain.php", formData);
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);
export const keywordscategories = createAsyncThunk(
    "data/keywords",
    async (formData: any) => {
        try {
            const data = await API.post(
                "/keywords_for_categories.php",
                formData
            );
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);
export const keywordcat_graph = createAsyncThunk(
    "data/keywordGraph",
    async (formData: any) => {
        try {
            const data = await API.post("/keywordcat_graphp.php", formData);
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);
export const domainMetrics_by_categories = createAsyncThunk(
    "data/metrics_by_categories",
    async (formData: any) => {
        try {
            const data = await API.post(
                "/domain_metrics_by_categories.php",
                formData
            );
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);
export const categories_more = createAsyncThunk(
    "data/categories_more",
    async (id: any) => {
        // const [first] = id.split("$", "");
        try {
            const data = await API.post(
                "/domain_metrics_by_categories_more.php",
                id
            );
            return data;
        } catch (error) {
            console.error(error);
        }
    }
);

export const topSearches = createAsyncThunk(
    "data/topSearches",
    async (formData: any) => {
        try {
            const data = await API.post("/top_searches.php", formData);
            return data;
        } catch (error) {
            console.log("topSearches error", error);
        }
    }
);

const dataSlice = createSlice({
    name: "marketanalysis",
    initialState: {
        categorie: [],
        category: [],
        keywords: [],
        keywordGraph: [],
        topSearchGraph: [],
        categoriesMore: [],
        Metrics_by_categories: [],
        metricsByCategory: {},
        topSearch: [],
        keywordDetails: "",
        status: "idle",
        formLoading: false,
        graphLoading: false,
        error: null,
    } as any,
    reducers: {
        keywordSave: (state, action) => {
            state.keywordDetails = action.payload;
        },
        metricesByCategories: (state, action) => {
            state.metricsByCategory = action.payload;
        },
        resetkeywords: (state) => {
            state.keywords = [];
        },
        resetkeywordGraph: (state) => {
            state.keywordGraph = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(categories.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(categories.fulfilled, (state, action) => {
                state.formLoading = false;
                state.categorie = action.payload;
            })
            .addCase(categories.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(keywordscategories.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(keywordscategories.fulfilled, (state, action) => {
                state.formLoading = false;
                state.keywords = action.payload;
                state.keywordGraph = [];
            })
            .addCase(keywordscategories.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(keywordcat_graph.pending, (state) => {
                state.graphLoading = true;
            })
            .addCase(keywordcat_graph.fulfilled, (state, action) => {
                state.graphLoading = false;
                if (action.meta.arg.title === "top search") {
                    state.topSearchGraph = action.payload;
                } else {
                    state.keywordGraph = action.payload;
                }
            })
            .addCase(keywordcat_graph.rejected, (state, action) => {
                state.graphLoading = false;
            })
            .addCase(selectCategories.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(selectCategories.fulfilled, (state, action) => {
                state.formLoading = false;
                state.category = action.payload;
            })
            .addCase(selectCategories.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(domainMetrics_by_categories.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(domainMetrics_by_categories.fulfilled, (state, action) => {
                state.formLoading = false;
                state.Metrics_by_categories = action.payload;
            })
            .addCase(domainMetrics_by_categories.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(categories_more.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(categories_more.fulfilled, (state, action) => {
                state.formLoading = false;
                state.categoriesMore = action.payload;
            })
            .addCase(categories_more.rejected, (state, action) => {
                state.formLoading = false;
            })
            .addCase(topSearches.pending, (state) => {
                state.formLoading = true;
            })
            .addCase(topSearches.fulfilled, (state, action) => {
                state.formLoading = false;
                state.topSearch = action.payload;
                state.topSearchGraph = [];
            })
            .addCase(topSearches.rejected, (state, action) => {
                state.formLoading = false;
            });
    },
});
export const {
    keywordSave,
    metricesByCategories,
    resetkeywords,
    resetkeywordGraph,
} = dataSlice.actions;
export default dataSlice.reducer;

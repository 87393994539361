import {
    BarChart,
    Link,
    Home,
    Users,
    Icon,
    TrendingUp,
    Link2,
    User,
    Plus,
} from "react-feather";
import { SiWebauthn } from "react-icons/si";
import { SiGooglesearchconsole } from "react-icons/si";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { VscSymbolKeyword } from "react-icons/vsc";
import { MdOutlineWebhook } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { TbMilitaryRank } from "react-icons/tb";
import { PiUsersThreeBold } from "react-icons/pi";
import { SiLinkerd } from "react-icons/si";
import { MdWebStories } from "react-icons/md";
import { FaBuildingUser } from "react-icons/fa6";
import { BsFillSignIntersectionYFill } from "react-icons/bs";
import { RiPagesFill } from "react-icons/ri";
import { FaLink, FaSearchengin } from "react-icons/fa6";
import { TbNetwork } from "react-icons/tb";
import { RiDatabaseLine } from "react-icons/ri";
import { TbDatabaseShare } from "react-icons/tb";
import { TbDatabasePlus, TbDeviceIpadHorizontalSearch } from "react-icons/tb";
import { MdEnergySavingsLeaf } from "react-icons/md";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { TbPackages } from "react-icons/tb";
import { PiUserListFill, PiBugDroidDuotone } from "react-icons/pi";
import { MdOutlineScreenSearchDesktop } from "react-icons/md";
import { MdOutlineManageSearch } from "react-icons/md";
import { TbChartHistogram } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { FaUsersCog } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import { LuPackageSearch } from "react-icons/lu";
import { GoProject } from "react-icons/go";
import { FaWpforms } from "react-icons/fa6";


const superMenu: {
    name: string;
    icon: Icon;
    route?: string;
    megamenu?: {
        name: string;
        icon?: Icon;
        route?: string;
        menu?: {
            name: string;
            route: string;
        }[];
    }[];
}[] = [
    {
        name: "Dashboard",
        icon: Home,
        route: "/",
    },
    {
        name: "Rank Tracker",
        icon: TbChartHistogram,
        megamenu: [
            {
                name: "All Projects",
                icon: PiUserListFill,
                route: "/rank-tracker/dashboard",
            },
            {
                name: "Rankings",
                icon: BarChart,
                menu: [
                    {
                        name: "Summary",
                        route: "/rank-tracker/summary",
                    },
                    {
                        name: "Detailed",
                        route: "/rank-tracker/detailed",
                    },
                    {
                        name: "OverAll",
                        route: "/rank-tracker/overall",
                    },
                    {
                        name: "Historical",
                        route: "/rank-tracker/historical",
                    },
                ],
            },
            {
                name: "My Competitors",
                icon: Users,
                menu: [
                    {
                        name: "Added Competitors",
                        route: "/rank-tracker/competitors/added",
                    },
                    {
                        name: "SERP Competitors",
                        route: "/rank-tracker/competitors/serp",
                    },
                    {
                        name: "Visibility Rating",
                        route: "/rank-tracker/competitors/visibility-Rating",
                    },
                ],
            },
            {
                name: "Website Audit",
                icon: MdOutlineScreenSearchDesktop,
                menu: [
                    {
                        name: "Overview",
                        route: "/rank-tracker/audit/overview",
                    },
                    {
                        name: "Crawled Pages",
                        route: "/rank-tracker/audit/crawled-pages",
                    },
                    {
                        name: "Found Resources",
                        route: "/rank-tracker/audit/found-resources",
                    },
                ],
            },
            {
                name: "Backlinks Monitor",
                icon: Link,
                menu: [
                    {
                        name: "Dashboard",
                        route: "/rank-tracker/backlinks-dashboard",
                    },
                    {
                        name: "Backlinks",
                        route: "/rank-tracker/backlinks",
                    },
                    {
                        name: "Page Intersection",
                        route: "/rank-tracker/page-intersection",
                    },
                    // {
                    //     name: "Anchor",
                    //     route: "/rank-tracker/anchor",
                    // },
                    // {
                    //     name: "Domain Intersection",
                    //     route: "/rank-tracker/domain-intersection",
                    // },

                    // {
                    //     name: "Page Intersection method two",
                    //     route: "/rank-tracker/page-intersection-two",
                    // },
                    // {
                    //     name: "Competitors",
                    //     route: "/backlinks/competitors",
                    // },

                    // {
                    //     name: "Bulk Ranks",
                    //     route: "/rank-tracker/bulkranks",
                    // },
                    // {
                    //     name: "Bulk Backlinks",
                    //     route: "/rank-tracker/bulk_backlinks",
                    // },

                    // {
                    //     name: "Bulk SpamScore",
                    //     route: "/rank-tracker/bulk_spamscore",
                    // },
                    // {
                    //     name: "Domain Pages",
                    //     route: "/rank-tracker/domain_pages",
                    // },
                    // {
                    //     name: "Referring Domain",
                    //     route: "/rank-tracker/referring_domain",
                    // },
                    // {
                    //     name: "Domain Page Summery",
                    //     route: "/rank-tracker/domain_page_summary",
                    // },
                    // {
                    //     name: "Referring Networks",
                    //     route: "/rank-tracker/referring_networks",
                    // },
                    // {
                    //     name: "Timeseries Summery",
                    //     route: "/rank-tracker/timeseries_summery",
                    // },
                    // {
                    //     name: "N&L Timeseries",
                    //     route: "/backlinks/nltimeseries",
                    // },
                    // {
                    //     name: "Bulk ReferringDomains",
                    //     route: "/backlinks/bulk_referringdomains",
                    // },
                    // {
                    //     name: "Bulk N&L Backlinks",
                    //     route: "/backlinks/bulk_nlbacklinks",
                    // },
                    // {
                    //     name: "Bulk N&L Referring",
                    //     route: "/backlinks/bulk_nlreferring",
                    // },
                ],
            },
        ],
    },
    {
        name: "Website Audit",
        icon: MdOutlineScreenSearchDesktop,
        megamenu: [
            // {
            //     name: "Add Audit",
            //     route: "/audit/add",
            //     icon: Plus,
            // },
            {
                name: "Add Audit Form",
                route: "/audit/audit-form",
                icon: FaWpforms,
            },{
                name: "OnPage Projects",
                route: "/audit/onpage-projects",
                icon: GoProject,
            },
            {
                name: "Overview",
                route: "/audit/overview",
                icon: FaSearchengin,
            },
            {
                name: "Crawled Pages",
                route: "/audit/crawled-pages",
                icon: PiBugDroidDuotone,
            },
            {
                name: "Found Resources",
                route: "/audit/found-resources",
                icon: TbDeviceIpadHorizontalSearch,
            },
            {
                name: "Density",
                route: "/audit/density",
                icon: TbDeviceIpadHorizontalSearch,
            },
            {
                name: "Issue Report",
                route: "/audit/issue-report",
                icon: TbDeviceIpadHorizontalSearch,
            },
            {
                name: "Found Links",
                route: "/audit/found-links",
                icon: PiBugDroidDuotone,
            },
            {
                name: "Crawl Comparison",
                route: "/audit/crawl-comparison",
                icon: PiBugDroidDuotone,
            },
            {
                name: "On Page Seo",
                route: "/audit/onPage-pages",
                icon: TbDeviceIpadHorizontalSearch,
            },{
                name: "On Page Redirect",
                route: "/audit/onpage-redirect",
                icon: TbDeviceIpadHorizontalSearch,
            },
        ],
    },
    {
        name: "Market Research",
        icon: TrendingUp,
        megamenu: [
            {
                icon: SiWebauthn,
                name: "Categories for Domain",
                route: "/categories_for_domain",
            },
            {
                icon: VscSymbolKeyword,
                name: "keywords for Categories",
                route: "/keywords_for_categories",
            },
            {
                icon: MdOutlineWebhook,
                name: "Domainmetrics by Categories",
                route: "/domain_metrics_by_categories",
            },
            {
                icon: TbReportSearch,
                name: "Top Searches",
                route: "/top_searches",
            },
        ],
    },
    {
        name: "Competitors Research",
        icon: BarChart,
        megamenu: [
            {
                name: "Ranked Keywords",
                route: "/ranked_keywords",
                icon: TbMilitaryRank,
            },
            {
                name: "Serp Competitors",
                route: "/serp_competitors",
                icon: PiUsersThreeBold,
            },
            {
                name: "Subdomains",
                route: "/subdomains",
                icon: SiLinkerd,
            },
            {
                name: "Relevant Pages",
                route: "/relevant_pages",
                icon: MdWebStories,
            },
            {
                name: "Competitor Domain",
                route: "/competitor_domain",
                icon: FaBuildingUser,
            },
            // {
            //     name: "Rank keyword 2",
            //     route: "/rank_keyword_two",
            //     icon: FaBuildingUser,
            // },
            // {
            //     name: "Historical Rank",
            //     route: "/historical_rank",
            //     icon: RxCountdownTimer,
            // },
            // {
            //     name: "Historical Serp",
            //     route: "/historical_serps",
            //     icon: TbCalendarTime,
            // },
            // {
            //     name: "Bulk Traffic Estimation",
            //     route: "/bulk_traffic_estimation",
            //     icon: BsLayerForward,
            // },
            // {
            //     name: "Domain Intersection",
            //     route: "/competitor_domain_intersection",
            //     icon: BsFillSignIntersectionYFill,
            // },
            // {
            //     name: "Page Intersection",
            //     route: "/competitor_page_intersection",
            //     icon: RiPagesFill,
            // },
            // {
            //     name: "Domain Whois",
            //     route: "/domain_whois_overview",
            //     icon: PiGlobeHemisphereEastFill,
            // },
        ],
    },
    {
        name: "Backlinks Research",
        icon: Link2,
        megamenu: [
            {
                name: "Backlinks",
                route: "/backlinks",
                icon: FaLink,
            },
            // {
            //     name: "onloadBacklinks",
            //     route: "/onloadbacklinks",
            //     icon: FaLink,
            // },

            {
                name: "Domain Intersection",
                route: "/backlinks/domain_intersection",
                icon: BsFillSignIntersectionYFill,
            },
            {
                name: "Page Intersection",
                route: "/backlinks/page_intersection",
                icon: RiPagesFill,
            },
            {
                name: "Bulk Ranks",
                route: "/backlinks/bulkranks",
                icon: RiDatabaseLine,
            },
            {
                name: "Bulk Backlinks",
                route: "/backlinks/bulk_backlinks",
                icon: TbDatabaseShare,
            },

            {
                name: "Bulk SpamScore",
                route: "/backlinks/bulk_spamscore",
                icon: TbDatabasePlus,
            },
            {
                name: "Domain Pages",
                route: "/backlinks/domain_pages",
                icon: TbNetwork,
            },
        ],
    },
    {
        name: "Keywords Research",
        icon: MdOutlineManageSearch,
        megamenu: [
            {
                name: "Organic Research",
                route: "/keywords/suggestions",
                icon: MdEnergySavingsLeaf,
            },
            {
                name: "Paid Research",
                route: "/keywords/suggestions/paid",
                icon: FaMoneyBillTrendUp,
            },
        ],
    },
    {
        name: "Analytics & Traffic",
        icon: SiGooglesearchconsole,
        // route: "/search-console",
        megamenu: [
            {
                name: "Google Search Console",
                icon: Home,
                route: "/search-console/overview",
            },
            // {
            //     name: "Performance",
            //     icon: MdOutlineHttps,
            //     route: "/search-console/performance",
            // },
        ],
    },
    {
        name: "Admin",
        icon: MdOutlineAdminPanelSettings,
        megamenu: [
            {
                name: "Financial",
                route: "/financial",
                icon: LiaMoneyBillWaveSolid,
            },
            {
                name: "Arrange Pricing",
                icon: TbPackages,
                route: "/arrange-pricing",
            },
            {
                name: "Users",
                icon: FaUsersCog,
                route: "/users",
            },
            {
                name: "Package creator",
                icon: LuPackageSearch,
                route: "/package-creator",
            },
        ],
    },

    {
        name: "Profile",
        icon: User,
        route: "/account/profile",
        // megamenu: [
        //     {
        //         name: "Profile",
        //         icon: User,
        //         route: "/account/profile",
        //     },
        //     {
        //         name: "Request History",
        //         icon: MdOutlineHttps,
        //         route: "/account/request-history",
        //     },
        //     {
        //         name: "Billing History",
        //         route: "/account/billings",
        //         icon: LiaFileInvoiceDollarSolid,
        //     },
        // ],
    },
    {
        name: "Pricing",
        icon: GiMoneyStack,
        route: "/pricing",
    },
];

export default superMenu;

import { LazyExoticComponent, lazy } from "react";

type section =
    | "auth"
    | "rank-tracker/main"
    | "rank-tracker/myCompetitor"
    | "rank-tracker/backlink"
    | "rank-tracker/audit"
    | "audit"
    | "market-research"
    | "competitors-research"
    | "backlinks-research"
    | "keywords-research"
    | "search-console"
    | "admin"
    | "account"
    | "common";
export interface IRoute {
    name: string;
    section: section;
    route: string;
    component: LazyExoticComponent<() => JSX.Element>;
    protections?: "admin" | "token";
    sidebar?: boolean;
}
const routes: IRoute[] = [
    {
        name: "Dashboard",
        section: "common",
        route: "/",
        component: lazy(() => import("../pages/analytics-dashboard/dashboard")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Login",
        section: "auth",
        route: "/login",
        component: lazy(() => import("../pages/signin")),
    },
    {
        name: "Two-Fa",
        section: "auth",
        route: "/twofa/:token",
        component: lazy(() => import("../pages/twoFa")),
    },
    {
        name: "Sign up",
        section: "auth",
        route: "/signup",
        component: lazy(() => import("../pages/signup")),
    },
    {
        name: "Forgot Password",
        section: "auth",
        route: "/forgot-password",
        component: lazy(() => import("../pages/forgot-password")),
    },
    {
        name: "All Projects",
        section: "rank-tracker/main",
        route: "rank-tracker/dashboard",
        component: lazy(() => import("../pages/rank-tracker/dashboard")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Detailed",
        section: "rank-tracker/main",
        route: "/rank-tracker/detailed",
        component: lazy(() => import("../pages/rank-tracker/detailed")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Overall",
        section: "rank-tracker/main",
        route: "/rank-tracker/overall",
        component: lazy(() => import("../pages/rank-tracker/overall")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Historical",
        section: "rank-tracker/main",
        route: "/rank-tracker/historical",
        component: lazy(() => import("../pages/rank-tracker/historical")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Summary",
        section: "rank-tracker/main",
        route: "/rank-tracker/summary",
        component: lazy(() => import("../pages/rank-tracker/summary")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Create Project",
        section: "rank-tracker/main",
        route: "/rank-tracker/form",
        component: lazy(() => import("../pages/rank-tracker/form")),
        protections: "token",
        sidebar: false,
    },
    {
        name: "Edit Project",
        section: "rank-tracker/main",
        route: "/rank-tracker/form/:id",
        component: lazy(() => import("../pages/rank-tracker/form")),
        protections: "token",
        sidebar: false,
    },
    {
        name: "Audit Overview",
        section: "audit",
        route: "/audit/overview",
        component: lazy(() => import("../pages/web-audit/overview")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Audit Settings",
        section: "audit",
        route: "/audit/settings",
        component: lazy(() => import("../pages/web-audit/settings")),
        protections: "token",
        sidebar: false,
    },
    {
        name: "Issue Report",
        section: "audit",
        route: "/audit/issue-report",
        component: lazy(() => import("../pages/web-audit/issue-report")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Found Links",
        section: "audit",
        route: "/audit/found-links",
        component: lazy(() => import("../pages/web-audit/found-links")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Crawl Comparison",
        section: "audit",
        route: "/audit/crawl-comparison",
        component: lazy(() => import("../pages/web-audit/crawl-comparsion")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Crawled Pages",
        section: "audit",
        route: "/audit/crawled-pages",
        component: lazy(() => import("../pages/web-audit/crawled-pages")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Found Resources",
        section: "audit",
        route: "/audit/found-resources",
        component: lazy(() => import("../pages/web-audit/found-resources")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Density",
        section: "audit",
        route: "/audit/density",
        component: lazy(() => import("../pages/web-audit/density")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "On Page",
        section: "audit",
        route: "/audit/onPage-pages",
        component: lazy(() => import("../pages/web-audit/on-pages")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "OnPage Project",
        section: "audit",
        route: "/audit/onpage-projects",
        component: lazy(() => import("../pages/web-audit/onpage-projects")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "OnPage Redirect",
        section: "audit",
        route: "/audit/onpage-redirect",
        component: lazy(() => import("../pages/web-audit/onpage-redirect")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Audit Overview",
        section: "rank-tracker/audit",
        route: "/audit/overview",
        component: lazy(() => import("../pages/web-audit/overview")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Audit Settings",
        section: "rank-tracker/audit",
        route: "/audit/settings",
        component: lazy(() => import("../pages/web-audit/settings")),
        protections: "token",
        sidebar: false,
    },
    {
        name: "Crawled Pages",
        section: "rank-tracker/audit",
        route: "/audit/crawled-pages",
        component: lazy(() => import("../pages/web-audit/crawled-pages")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Found Resources",
        section: "rank-tracker/audit",
        route: "/audit/found-resources",
        component: lazy(() => import("../pages/web-audit/found-resources")),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Audit Form",
        section: "audit",
        route: "/audit/add",
        component: lazy(() => import("../pages/website-audit/form")),
        protections: "token",
        sidebar: false,
    },
    {
        name: "Add Audit Form",
        section: "audit",
        route: "/audit/audit-form",
        component: lazy(() => import("../pages/website-audit/audit-form")),
        protections: "token",
        sidebar: false,
    },
    {
        name: "Added Competitors",
        section: "rank-tracker/myCompetitor",
        route: "/rank-tracker/competitors/added",
        component: lazy(() => import("../pages/my-competitors/added")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Serp Competitors",
        section: "rank-tracker/myCompetitor",
        route: "/rank-tracker/competitors/serp",
        component: lazy(() => import("../pages/my-competitors/serp")),
        protections: "token",
        sidebar: true,
    },

    {
        name: "visibility Rating Competitors",
        section: "rank-tracker/myCompetitor",
        route: "/rank-tracker/competitors/visibility-Rating",
        component: lazy(
            () => import("../pages/my-competitors/visibility-rating")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Compare Competitor",
        section: "rank-tracker/myCompetitor",
        route: "/rank-tracker/competitors/compare-comp/:competitor/:section",
        component: lazy(() => import("../pages/my-competitors/compare")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/backlinks",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/backlinks")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/backlinks",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/backlinks")
        ),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Backlinks dashboard",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/backlinks-dashboard",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/dashboard")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink Anchor",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/anchor",
        component: lazy(() => import("../pages/rank-tracker/backlink/anchor")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink Intersection",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/page-intersection",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/pageIntersection")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink Intersection Two",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/page-intersection-two",
        component: lazy(
            () =>
                import(
                    "../pages/rank-tracker/backlink/pageIntersectionMethodTwo"
                )
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink Domain Intersection",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/domain-intersection",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/domainIntersection")
        ),
        protections: "token",
        sidebar: true,
    },
    // {
    //     name: "Backlink Domain Pages",
    //     section: "rank-tracker/backlink",
    //     route: "/backlinks/domain_pages",
    //     component: lazy(
    //         () => import("../pages/rank-tracker/backlink/domainPages")
    //     ),
    //     protections: "token",
    //     sidebar: true,
    // },
    {
        name: "Backlink Reffering Domain",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/referring_domain",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/refferingDomain")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink Domain Page summary",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/domain_page_summary",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/domainPageSummery")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink timeseries",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/timeseries_summery",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/timeSeriesSummery")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlink competitors",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/competitors",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/competitors")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks nltimeseries",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/nltimeseries",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/timeSeries")
        ),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Backlinks bulk backlinks",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/bulk_backlinks",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/bulkBacklinks")
        ),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Backlinks bulk SpamScore",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/bulk_spamscore",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/bulkSpamScore")
        ),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Backlinks bulk Referring Domains",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/bulk_referringdomains",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/bulkReferringDoamins")
        ),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Backlinks bulk Nl backlinks",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/bulk_nlbacklinks",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/bulkNLBacklinks")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulk Nl referring",
        section: "rank-tracker/backlink",
        route: "/rank-tracker/bulk_nlreferring",
        component: lazy(
            () => import("../pages/rank-tracker/backlink/bulkNLReferring")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Market anal  categories for domain",
        section: "market-research",
        route: "/categories_for_domain",
        component: lazy(() => import("../pages/market-analysis/categories")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Market anal Keywords for categories",
        section: "market-research",
        route: "/keywords_for_categories",
        component: lazy(
            () => import("../pages/market-analysis/keyword-categories")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Market anal Domain metrics by categories",
        section: "market-research",
        route: "/domain_metrics_by_categories",
        component: lazy(
            () =>
                import("../pages/market-analysis/domain-metrics-by-categories")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Market anal Top searches",
        section: "market-research",
        route: "/top_searches",
        component: lazy(() => import("../pages/market-analysis/top-searches")),
        protections: "token",
        sidebar: true,
    },
    // {
    //     name: "comp research Ranked keywords",
    //     section: "competitors-research",
    //     route: "/ranked_keywords",
    //     component: lazy(
    //         () => import("../pages/competitors-research/ranked-keywords")
    //     ),
    //     protections: "token",
    //     sidebar: true,
    // },
    {
        name: "comp research Ranked keywords",
        section: "competitors-research",
        route: "/ranked_keywords",
        component: lazy(
            () => import("../pages/competitors-research/ranked-keywords-two")
        ),
        protections: "token",
        sidebar: true,
    },
    // {
    //     name: "comp research Ranked keywords 2",
    //     section: "competitors-research",
    //     route: "/rank_keyword_two",
    //     component: lazy(
    //         () => import("../pages/competitors-research/ranked-keywords-two")
    //     ),
    //     protections: "token",
    //     sidebar: true,
    // },
    {
        name: "comp research Serp Competitors",
        section: "competitors-research",
        route: "/serp_competitors",
        component: lazy(
            () => import("../pages/competitors-research/serp-competitors")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research subdomains",
        section: "competitors-research",
        route: "/subdomains",
        component: lazy(
            () => import("../pages/competitors-research/subdomains")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research relevant pages",
        section: "competitors-research",
        route: "/relevant_pages",
        component: lazy(
            () => import("../pages/competitors-research/relevant-pages")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research competitor domain",
        section: "competitors-research",
        route: "/competitor_domain",
        component: lazy(
            () => import("../pages/competitors-research/competitor-domain")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research historical rank",
        section: "competitors-research",
        route: "/historical_rank",
        component: lazy(
            () => import("../pages/competitors-research/historical-rank")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research historical serps",
        section: "competitors-research",
        route: "/historical_serps",
        component: lazy(
            () => import("../pages/competitors-research/historical-serps")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research competitor domain intersection",
        section: "competitors-research",
        route: "/competitor_domain_intersection",
        component: lazy(
            () => import("../pages/competitors-research/domain-intersection")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research competitor page intersection",
        section: "competitors-research",
        route: "/competitor_page_intersection",
        component: lazy(
            () => import("../pages/competitors-research/page-intersection")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research Domain whois",
        section: "competitors-research",
        route: "/domain_whois_overview",
        component: lazy(
            () => import("../pages/competitors-research/domain-whois")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "comp research bulk traffic estimation",
        section: "competitors-research",
        route: "/bulk_traffic_estimation",
        component: lazy(
            () =>
                import("../pages/competitors-research/bulk-traffic-estimation")
        ),
        protections: "token",
        sidebar: true,
    },
    {
        name: "keywords suggestions",
        section: "keywords-research",
        route: "/keywords/suggestions",
        component: lazy(() => import("../pages/keywords/suggestions")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "keywords suggestions paid",
        section: "keywords-research",
        route: "/keywords/suggestions/paid",
        component: lazy(() => import("../pages/keywords/paid")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Search Console",
        section: "search-console",
        route: "/search-console/overview",
        component: lazy(() => import("../pages/search-console/overview")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Search Console",
        section: "search-console",
        route: "/search-console/performance",
        component: lazy(() => import("../pages/search-console/performance")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "keywords items-types",
        section: "keywords-research",
        route: "/keywords/items-types/:type",
        component: lazy(() => import("../pages/keywords/items-types")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Financial dashboard",
        section: "admin",
        route: "/financial",
        component: lazy(() => import("../pages/admin/financialDashboard")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Packages create package",
        section: "admin",
        route: "/arrange-pricing",
        component: lazy(() => import("../pages/custom-packages")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Packages add package",
        section: "admin",
        route: "/add-packages",
        component: lazy(() => import("../pages/add-packages")),
        protections: "admin",
        sidebar: true,
    },
    {
        name: "Pricing",
        section: "common",
        route: "/pricing",
        component: lazy(() => import("../pages/pricing")),
    },
    {
        name: "Backlinks",
        section: "backlinks-research",
        route: "/backlinks",
        component: lazy(() => import("../pages/backlink/backlinks")),
        protections: "token",
        sidebar: true,
    },

    {
        name: "Backlinks Anchor",
        section: "backlinks-research",
        route: "/backlinks/anchor",
        component: lazy(() => import("../pages/backlink/anchor")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks page intersection",
        section: "backlinks-research",
        route: "/backlinks/page_intersection",
        component: lazy(() => import("../pages/backlink/pageIntersection")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks domain intersection",
        section: "backlinks-research",
        route: "/backlinks/domain_intersection",
        component: lazy(() => import("../pages/backlink/domainIntersection")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks domain pages",
        section: "backlinks-research",
        route: "/backlinks/domain_pages",
        component: lazy(() => import("../pages/backlink/domainPages")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks reffering domain",
        section: "backlinks-research",
        route: "/backlinks/referring_domain",
        component: lazy(() => import("../pages/backlink/refferingDomain")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks domain page summary",
        section: "backlinks-research",
        route: "/backlinks/domain_page_summary",
        component: lazy(() => import("../pages/backlink/domainPageSummery")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks referring networks",
        section: "backlinks-research",
        route: "/backlinks/referring_networks",
        component: lazy(() => import("../pages/backlink/refferingNetworks")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks time Series Summery",
        section: "backlinks-research",
        route: "/backlinks/timeseries_summery",
        component: lazy(() => import("../pages/backlink/timeSeriesSummery")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks competitors",
        section: "backlinks-research",
        route: "/backlinks/competitors",
        component: lazy(() => import("../pages/backlink/competitors")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks Nl timeseries",
        section: "backlinks-research",
        route: "/backlinks/nltimeseries",
        component: lazy(() => import("../pages/backlink/timeSeries")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulkranks",
        section: "backlinks-research",
        route: "/backlinks/bulkranks",
        component: lazy(() => import("../pages/backlink/bulkRanks")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulk backlinks",
        section: "backlinks-research",
        route: "/backlinks/bulk_backlinks",
        component: lazy(() => import("../pages/backlink/bulkBacklinks")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulk spamscore",
        section: "backlinks-research",
        route: "/backlinks/bulk_spamscore",
        component: lazy(() => import("../pages/backlink/bulkSpamScore")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulk Referring Doamins",
        section: "backlinks-research",
        route: "/backlinks/bulk_referringdomains",
        component: lazy(() => import("../pages/backlink/bulkReferringDoamins")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulk NL Backlinks",
        section: "backlinks-research",
        route: "/backlinks/bulk_nlbacklinks",
        component: lazy(() => import("../pages/backlink/bulkNLBacklinks")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Backlinks bulk Nl referring",
        section: "backlinks-research",
        route: "/backlinks/bulk_nlreferring",
        component: lazy(() => import("../pages/backlink/bulkNLReferring")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Checkout",
        section: "common",
        route: "/checkout",
        component: lazy(() => import("../pages/checkout")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Thank You",
        section: "common",
        route: "/thankyou",
        component: lazy(() => import("../pages/thankyou")),
        protections: "token",
    },
    // {
    //     name: "Request History",
    //     section: "account",
    //     route: "/account/request-history",
    //     component: lazy(() => import("../pages/account/request-history")),
    //     protections: "token",
    //     sidebar: true,
    // },
    {
        name: "Profile",
        section: "account",
        route: "/account/profile",
        component: lazy(() => import("../pages/profile")),
        protections: "token",
        sidebar: true,
    },
    {
        name: "Admin Users",
        section: "admin",
        route: "/users",
        component: lazy(() => import("../pages/admin/users")),
        protections: "admin",
        sidebar: true,
    },
    {
        name: "Admin Users",
        section: "admin",
        route: "/package-creator",
        component: lazy(() => import("../pages/admin/packages")),
        protections: "admin",
        sidebar: true,
    },
];
export const getRoutes = (
    type: "unprotected" | "tokenProtected" | "adminProtected"
): IRoute[] => {
    switch (type) {
        case "unprotected":
            return routes.filter((el) => !el?.protections?.length);
        case "tokenProtected":
            return routes.filter((el) => el?.protections?.includes("token"));
        case "adminProtected":
            return routes.filter((el) => el?.protections?.includes("admin"));
        default:
            return routes;
    }
};

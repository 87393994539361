/* eslint-disable */
import {
    ensureArray,
    formatDate,
    formatNumber,
    isDateValid,
    removeDuplicatesByKey,
    slugify,
} from "./formater-helper";

export const dataFilter = (rows: any[], cols: any[]) => {
    const Cols: any[] = cols?.filter((el) => el?.omit === false);

    const Rows: any[] = rows?.map((obj) => {
        const filteredKeys: any[] = Cols?.filter((col) =>
            obj?.hasOwnProperty(col?.for)
        )?.map((col) => col?.for);

        return filteredKeys?.reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {});
    });
    return {
        Rows: Rows,
        Cols: Cols,
    };
};
export const dataFixer = (data: any[]) => {
    const flattenedData: any = [];

    if (data && Array.isArray(data)) {
        data?.forEach((item: any, ind: any) => {
            if (item?.data) {
                return;
            } else {
                flattenedData?.push({
                    ...item,
                    no: ind + 1,
                    dates: formatDate(new Date(item?.dates)),
                });
            }
        });
    }

    return flattenedData;
};
export const dataFixer2 = (data: any[]) => {
    const flattenedData: any = [];
    if (data && Array.isArray(data)) {
        data?.forEach((item: any, ind: any) => {
            if (item?.data) {
                return [];
            } else {
                flattenedData?.push({
                    ...item,
                    no: ind + 1,
                });
            }
        });
    }
    return flattenedData;
};

export const removeData = (apiData: any) => {
    const flattenedData: any = [];
    apiData?.forEach((item: any, ind: any) => {
        if ("data" in item) {
            return;
        } else {
            flattenedData?.push({
                ...item,
            });
        }
    });
    return flattenedData;
};

const months: string[] = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
function calculateYear(monthIndex: number): number {
    const currentDate = new Date();
    const currentYear = currentDate?.getFullYear();
    const currentMonth = currentDate?.getMonth();

    return monthIndex < currentMonth ? currentYear : currentYear - 1;
}

export const addYear = (data: any) => {
    const newData = data?.map((dataObj: any, ind: any) => {
        const updatedDataObj: any = { ...dataObj };
        for (const key in updatedDataObj) {
            if (months?.includes(key)) {
                const monthIndex = new Date(
                    Date?.parse(key + " 1, 2023")
                )?.getMonth(); // Get the month index (0 - January, 1 - February, ?.?.?.)
                const year = calculateYear(monthIndex);
                updatedDataObj[`${key}_${year}`] = updatedDataObj[key];
                delete updatedDataObj[key];
            }
            updatedDataObj[`no`] = ind + 1;
            if (key === "row") {
                if (updatedDataObj[key].startsWith("pos")) {
                    updatedDataObj[key] = updatedDataObj[key]
                        .replace("pos_", "")
                        .replace("_", "-");
                }
            }
        }
        return updatedDataObj;
    });
    return newData;
};
function getMonthIndex(monthName: string): number {
    return months?.indexOf(monthName);
}
export const ColumnCreator = () => {
    const data = months
        ?.map((month) => ({
            name: `${month} ${calculateYear(
                new Date(Date?.parse(month + " 1, 2023"))?.getMonth()
            )}`,
            selector: (row: any) =>
                row[
                    `${month}_${calculateYear(
                        new Date(Date?.parse(month + " 1, 2023"))?.getMonth()
                    )}`
                ],
            sortable: false,
            omit: false,
            for: `${month}_${calculateYear(
                new Date(Date?.parse(month + " 1, 2023"))?.getMonth()
            )}`,
        }))
        ?.sort((a, b) => {
            const monthA = a?.name?.substring(0, 3);
            const yearA = parseInt(a?.name?.substring(4));
            const monthB = b?.name?.substring(0, 3);
            const yearB = parseInt(b?.name?.substring(4));

            if (yearA !== yearB) {
                return yearB - yearA;
            }
            return getMonthIndex(monthB) - getMonthIndex(monthA);
        });
    data.unshift({
        name: `Positions`,
        selector: (row: any) => row.row,
        sortable: false,
        omit: false,
        for: `row`,
    });
    data.unshift({
        name: `No`,
        selector: (row: any) => row.no,
        sortable: false,
        omit: false,
        for: `no`,
    });
    return data;
};

export const serpAdder = (arr1: any[], arr2: any[]) => {
    const arr: any[] = [];
    for (let i = 0; i < arr1.length; i++) {
        const serpItemsTypeArr: any[] = [];
        for (let j = 0; j < arr2.length; j++) {
            if (
                arr1[i]["targeted_id"] === arr2[j]["targeted_id"] &&
                arr1[i]["change"] === arr2[j]["change"]
            ) {
                serpItemsTypeArr.push(arr2[j]["names"]);
            }
        }
        const mergedObject = {
            ...arr1[i],
            serp_items_type: serpItemsTypeArr,
        };
        arr.push(mergedObject);
    }
    return arr;
};
export const intentAdder = (arr1: any[], arr2: any[]) => {
    const arr: any[] = [];

    for (let i = 0; i < arr1.length; i++) {
        const serpItemsTypeArr: any[] = [];
        for (let j = 0; j < arr2.length; j++) {
            if (arr1[i]["change"] === arr2[j]["change"]) {
                serpItemsTypeArr.push(arr2[j]["vv"]);
            }
        }
        const mergedObject = {
            ...arr1[i],
            intent: serpItemsTypeArr,
        };
        arr.push(mergedObject);
    }
    return arr;
};
export function dataTableGraphs(arr: any[], chunkSize: number, ind: number) {
    const outputArray: any = [];
    if (!arr?.length) {
        return outputArray;
    }
    for (let i = 0; i < arr.length; i += chunkSize) {
        outputArray.push(arr.slice(i, i + chunkSize));
    }
    return outputArray[ind];
}

export function convertArrayToObjects(inputArray: any[]) {
    const result: any[] = [];
    let currentObject: any = {};

    for (let i = 0; i < inputArray.length; i++) {
        const currentElement = inputArray[i];

        if (currentElement === "desktop") {
            if (Object.keys(currentObject).length > 0) {
                result.push(currentObject);
                currentObject = {};
            }
        } else if (currentElement === "dynamic") {
            currentObject.dynamic = 0;
        } else {
            if (!currentObject.keyword) {
                currentObject.keyword = currentElement;
            } else if (!currentObject.url) {
                currentObject.url = currentElement;
            } else if (!currentObject.search_volume) {
                currentObject.search_volume = currentElement;
            } else if (!currentObject.serp_feature) {
                currentObject.serp_feature = currentElement.split(",");
            } else {
                currentObject.serp_feature.push(currentElement);
            }
        }
    }

    if (Object.keys(currentObject).length > 0) {
        result.push(currentObject);
    }

    return result;
}

export function createSelectors(data: any[]) {
    const selectors: any[] = [];

    for (let i = 0; i < data.length; i++) {
        for (const key in data[i]) {
            if (isDateValid(key)) {
                selectors.push({
                    name: key,
                    selector: (row: any) => row?.[key],
                    sortable: false,
                    omit: false,
                    for: key,
                    tooltip: `<p style="text-align:center;max-width:170px;color:#fff"><em>URL of the image resource</em><br />the URL leading to the resource where the image is posted</p>`,
                });
            } else if (key.includes(",")) {
                selectors.push({
                    name: key,
                    selector: (row: any) => row?.[key],
                    sortable: false,
                    omit: false,
                    for: key,
                    tooltip: `<p style="text-align:center;max-width:170px;color:#fff"><em>URL of the image resource</em><br />the URL leading to the resource where the image is posted</p>`,
                });
            }
        }
    }

    return removeDuplicatesByKey(selectors, "name")?.sort((a, b) => {
        const dateA: any = new Date(a.name);
        const dateB: any = new Date(b.name);
        return dateA - dateB;
    });
}

export const topCreator = (obj: any, arr: number[]) => {
    const allValue = obj?.all || 0;
    const newArray = (ensureArray(arr) || [])
        ?.slice(0, -6)
        ?.map((val, i) => val - arr[i + 6]);
    newArray.unshift(0);
    return Object?.keys(obj || {})?.map((el, ind) => {
        const title =
            el?.toLowerCase() === "all" ? "All" : el.replace(/^\D+/g, "");
        const value = obj?.[`${el}`];
        const percentage = (value / allValue) * 100;
        return {
            title: title,
            value: formatNumber(value),
            percentage: formatNumber(percentage),
            upDown: formatNumber(newArray[ind] || 0),
        };
    });
};

// export const trimObjectValues = (obj: any) => {
//     const trimmedObj: any = {};
//     for (const key in obj) {
//         if (typeof obj[key] === "string") {
//             trimmedObj[key] = obj[key].trim();
//         } else if (Array.isArray(obj[key])) {
//             trimmedObj[key] = obj[key].map((item: any) =>
//                 trimObjectValues(item)
//             );
//         } else {
//             trimmedObj[key] = obj[key];
//         }
//     }
//     return trimmedObj;
// };

export const trimObjectValues = (obj: any): any => {
    const trimmedObj: any = {};
    for (const key in obj) {
        if (typeof obj[key] === "string") {
            trimmedObj[key] = obj[key].trim();
        } else if (Array.isArray(obj[key])) {
            trimmedObj[key] = obj[key].map((item: any) =>
                typeof item === "string" ? item.trim() : item
            );
        } else if (typeof obj[key] === "object") {
            trimmedObj[key] = trimObjectValues(obj[key]);
        } else {
            trimmedObj[key] = obj[key];
        }
    }
    return trimmedObj;
};
export const trimValues = (input: any): any => {
    const trimString = (value: string | undefined) =>
        value ? value.trim() : "";

    return {
        ...input,
        domain: trimString(input.domain),
        targets: input.targets.map((target: any) => ({
            type: trimString(target.type),
            target: trimString(target.target),
        })),
        exclude_targets: input.exclude_targets.map((excludeTarget: any) => ({
            type: trimString(excludeTarget.type),
            target: trimString(excludeTarget.target),
        })),
        nonMappedField: {
            ...input.nonMappedField,
            status_type: trimString(input.nonMappedField.status_type),
            include_subdomains: trimString(
                input.nonMappedField.include_subdomains
            ),
            intersection_mode: trimString(
                input.nonMappedField.intersection_mode
            ),
        },
        filters: input.filters.map((filter: any) => ({
            ...filter,
            domain: trimString(filter.domain),
            filter: trimString(filter.filter),
            operator: trimString(filter.operator),
            filterValues: trimString(filter.filterValues),
            condition: trimString(filter.condition),
        })),
    };
};

export const getRoleByNumber = (
    type: any
): "Sub Admin" | "Manager / Accountant" | "User" | null => {
    const newType: 1 | 2 | 3 = Number(type) as any;
    if (!type || isNaN(newType)) {
        return null;
    }
    switch (newType) {
        case 1:
            return "Sub Admin";
        case 2:
            return "Manager / Accountant";
        case 3:
            return "User";
        default:
            return null;
    }
};
export const getNumberByRole = (
    role: "Sub Admin" | "Manager / Accountant" | "User"
): 1 | 2 | 3 | null => {
    if (!role) {
        return null;
    }
    switch (slugify(role?.trim()?.toLowerCase())) {
        case "sub_admin":
            return 1;
        case "manager_/_accountant":
            return 2;
        case "user":
            return 3;
        default:
            return null;
    }
};

export const transformData = (originalData: any) => {
    console.log("originalData", originalData);
    const groupedData: any = {};
    const copyProperties = (item: any, title: any) => {
        const copiedData: any = {};
        switch (title) {
            case "Rank Tracker":
                copiedData.update = item.rank_tracker_update;
                copiedData.allowed_keywords = `${item.rank_tracker_allowed_keywords_value ?? "--"} / ${item.rank_tracker_allowed_keywords_duration === "NULL" ? "--" : item.rank_tracker_allowed_keywords_duration}`;
                copiedData.backlinks = `${item.rank_tracker_backlinks_value ?? "--"} / ${item.rank_tracker_backlinks_duration === "NULL" ? "--" : item.rank_tracker_backlinks_duration}`;
                copiedData.allowed_projects = `${item.rank_tracker_allowed_projects_value ?? "--"} / ${item.rank_tracker_allowed_projects_duration === "NULL" ? "--" : item.rank_tracker_allowed_projects_duration}`;
                copiedData.allowed_max_competitor = `${item.rank_tracker_allowed_max_competitor_value ?? "--"} / ${item.rank_tracker_allowed_max_competitor_duration === "NULL" ? "--" : item.rank_tracker_allowed_max_competitor_duration}`;

                break;
            case "Market Research":
                copiedData.allowed_request = `${item.market_research_allowed_request_value ?? "--"} / ${item.market_research_allowed_request_duration === "NULL" ? "--" : item.market_research_allowed_request_duration}`;
                // copiedData.total_rows_per_request = `${item.market_research_totalRows_perReq_value ??"-"} / ${item.market_research_totalRows_perReq_duration ??"--"}`;
                break;
            case "Competitor Research":
                copiedData.allowed_request = `${item.competitors_research_allowed_request_value ?? "--"} / ${item.competitors_research_allowed_request_duration === "NULL" ? "--" : item.competitors_research_allowed_request_duration}`;
                // copiedData.allowed_keywords = `${item.competitors_research_allowed_keywords_value ?? "--"} / ${item.competitors_research_allowed_keywords_duration === "NULL" ? "--" : item.competitors_research_allowed_keywords_duration}`;
                // copiedData.total_rows_per_request = `${item.competitors_research_totalRows_perReq_value ??"-"} / ${item.competitors_research_totalRows_perReq_duration ??"--"}`;
                break;
            case "Backlink Research":
                // copiedData.allowed_backlinks = `${item.backlinks_research_allowed_backlinks_value ?? "--"} / ${item.backlinks_research_allowed_backlinks_duration === "NULL" ? "--" : item.backlinks_research_allowed_backlinks_duration}`;
                copiedData.allowed_request = `${item.backlinks_research_allowed_request_value ?? "-"} / ${item.backlinks_research_allowed_request_duration === "NULL" ? "--" : item.backlinks_research_allowed_request_duration}`;
                // copiedData.total_backlinks = `${item.backlinks_research_total_backlinks_value ?? "-"} / ${item.backlinks_research_total_backlinks_duration === "NULL" ? "--" : item.backlinks_research_total_backlinks_duration}`;
                break;
            case "Keyword Research":
                // copiedData.allowed_keywords = `${
                //     item.keyword_research_allowed_keywords_value ?? "--"
                // } / ${
                //     item.keyword_research_allowed_keywords_duration === "NULL"
                //         ? "--"
                //         : item.keyword_research_allowed_keywords_duration
                // }`;
                copiedData.allowed_request = `${item.keyword_research_allowed_request_value ?? "-"} / ${item.keyword_research_allowed_request_duration === "NULL" ? "--" : item.keyword_research_allowed_request_duration}`;
                break;
            default:
                break;
        }
        return copiedData;
    };
    ensureArray(originalData).forEach((item: any) => {
        const title: string[] = [];
        for (const key in item) {
            if (key.startsWith("rank_tracker")) {
                title.push("Rank Tracker");
            }
            if (key.startsWith("market_research")) {
                title.push("Market Research");
            }
            if (key.startsWith("competitors_research")) {
                title.push("Competitor Research");
            }
            if (key.startsWith("backlinks_research")) {
                title.push("Backlink Research");
            }
            if (key.startsWith("keyword_research")) {
                title.push("Keyword Research");
            }
        }
        const newTitle: any = [...new Set(title)];
        newTitle.forEach((val: any) => {
            if (!groupedData[val]) {
                groupedData[val] = {
                    title: val,
                    data: [],
                };
            }
            const copiedData: any = copyProperties(item, val);
            copiedData.package_name = item.package_name;
            groupedData[val].data.push(copiedData);
        });
    });
    const transformedData = Object.values(groupedData);
    return transformedData;
};
export  const getPreviousYearDates = (dataArray:string[]) => {
    return dataArray.map((obj:any) => {

        const currentDate = new Date(obj.last_seen);
            const previousYearDate = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());
            return { date: previousYearDate.toISOString().split('T')[0] };
    });
};

export const capitalCase=(value:string)=>{
    return value.charAt(0).toUpperCase() + value.slice(1)
};
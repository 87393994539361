import { createSlice } from "@reduxjs/toolkit";

const onPageProjectID = createSlice({
    name: "onPageProjectID",
    initialState: {
        value: 0,
    } as any,
    reducers: {
        setProjectID: (state, action) => {
            console.log('setProjectID action called with payload:', action.payload);
            state.value = action.payload;
        },
    },
});

export const { setProjectID } = onPageProjectID.actions;
export default onPageProjectID.reducer;

import styled, { device, themeGet, css } from "@doar/shared/styled";

interface IProps {
    $isFixed: boolean;
}

// const minimizeCSS = css`
//     justify-content: start;
//     padding-left: 2.5rem !important;
//     .aside-logo {
//         display: none;
//     }
// `;

export const StyledHeader = styled.header<IProps>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 4px;
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
    transition: all 0.2s ease-in-out;
    min-height: 60px;
    max-height: 60px;
    padding: 0 1rem;
    .minimize-btn svg {
        transition: all 0.2s ease-in-out;
    }
    .aside-logo {
        white-space: nowrap;
    }
`;

export const StyledMenuBtn = styled.button`
    padding: 0;
    background-color: transparent;
    border: none;
    line-height: 0.5;
    svg {
        color: ${themeGet("colors.text3")};
        margin-top: -3px;
    }
    &:hover,
    &:focus {
        svg {
            color: ${themeGet("colors.text2")};
        }
    }
    &.minimize-btn {
        display: none;
        ${device.xlarge} {
            display: block;
        }
    }
    &.display-btn {
        display: block;
        ${device.xlarge} {
            display: none;
        }
        svg {
            &:last-of-type {
                margin-top: 0;
                display: none;
            }
        }
    }
    ${({ theme }) =>
        theme.name === "dark" &&
        css`
            &:hover {
                color: ${themeGet("colors.white")};
            }
        `}
`;

interface IMenuBtn {
    $hasSidebar?: boolean;
    $sidebarOpen?: boolean;
    $bodyOpen?: boolean;
}

export const StyledSidebarBtn = styled.button<IMenuBtn>`
    padding: 0;
    background-color: transparent;
    border: none;
    line-height: 0.5;
    svg {
        color: ${themeGet("colors.text3")};
    }
    ${device.small} {
        margin-left: 20px;
    }
    ${device.large} {
        display: none;
    }

    ${({ $sidebarOpen, $bodyOpen }) =>
        $sidebarOpen &&
        !$bodyOpen &&
        css`
            display: none;
        `}
    ${({ $bodyOpen, $sidebarOpen }) =>
        $bodyOpen &&
        $sidebarOpen &&
        css`
            display: block;
        `}
    ${({ theme }) =>
        theme.name === "dark" &&
        css`
            &:hover {
                color: ${themeGet("colors.white")};
            }
        `}
`;

import { StyledLogo } from "./style";

const AsideLogo = () => {
    return (
        <StyledLogo className="aside-logo" path="/">
            Opti<span>linko</span>
        </StyledLogo>
    );
};

export default AsideLogo;

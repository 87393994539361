import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "src/utils/authConfig";

export const onpageProjects = createAsyncThunk(
    "data/onpage_projects",
    async () => {
        try {
            const response = await API.get("/onpage_projects.php");
            console.log(response)
            return response;
        } catch (error) {
            console.log(error);
        }
    }
);

const onPageSlice = createSlice({
    name: "onpage_projects",
    initialState: {
        status: false,
        data: [],
        loading: false,
        error: null,
    } as any,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(onpageProjects.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(onpageProjects.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(onpageProjects.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as any;
            });
    },
});

export default onPageSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const dataSlice = createSlice({
    name: "data",
    initialState: {
        auditsettings: {},
    },
    reducers: {
        addAuditSettings: (state, action) => {
            state.auditsettings = action.payload;
        },
    },
    extraReducers: (builder) => {},
});
export const { addAuditSettings } = dataSlice.actions;

export default dataSlice.reducer;

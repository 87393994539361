import { Theme, createTheme } from "@mui/material";

const getTheme = (theme: "light" | "dark"): Theme => {
    return createTheme({
        palette: {
            mode: theme,
            primary: {
                main: "#0168FA",
            },
            success: {
                main: "#36B37E",
            },
            secondary: {
                main: "#fff",
            },
            background: {
                paper: theme === "dark" ? "#1C273C" : "#fff",
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    containedPrimary: {
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#005ce6",
                        },
                    },
                    outlinedPrimary: {
                        color: "#0168FA",
                    },
                    containedSuccess: {
                        color: "#fff",
                        "&:hover": {
                            backgroundColor: "#35b17d",
                        },
                    },
                    outlinedSuccess: {
                        color: "#36B37E",
                    },
                    containedSecondary: {
                        color: "#000",
                        "&:hover": {
                            backgroundColor: "#f8f8f8",
                        },
                    },
                    outlinedSecondary: {
                        color: "#f8f8f8",
                    },
                },
            },
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        backgroundColor: theme === "light" ? "#fff" : "#1C273C",
                        backgroundImage: "unset !important",
                    },
                },
            },
        },
    });
};

export default getTheme;

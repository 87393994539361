import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { compareStr, ensureArray } from "src/helpers/formater-helper";
import API from "src/utils/authConfig";
export const getLocation = createAsyncThunk(
    "data/fetchLocation",
    async (val: any) => {
        try {
            const data = await API.get(
                `/serp_location.php?location=${val[0]}&type=${val[1]}`
            );

            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const submitForm = createAsyncThunk(
    "data/submitForm",
    async (val: any) => {
        try {
            const data = await API.post(`/serp_live.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const fetchDetailed = createAsyncThunk(
    "data/fetchdetailed",
    async (val: any) => {
        try {
            const data = await API.post(`/detailed_serp.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const fetchDetailed2 = createAsyncThunk(
    "data/fetchdetailed2",
    async (val: any) => {
        try {
            const data = await API.post(`/detailed_graphs.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const fetchOverAll = createAsyncThunk(
    "data/fetchOverAll",
    async (val: any) => {
        try {
            const data = await API.post(`/overall_rank.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const fetchHistorical = createAsyncThunk(
    "data/fetchHistorical",
    async (val: any) => {
        try {
            const data = await API.post(`/historical_rank.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const fetchProjects = createAsyncThunk(
    "data/fetchProjects",
    async () => {
        try {
            const data = await API.post(`/my_projects.php`);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const fetchDeviceChart = createAsyncThunk(
    "data/fetchDeviceChart",
    async (val: any) => {
        try {
            const data = await API.post(
                val?.type === "comp-detailed"
                    ? "/comp_keywords.php"
                    : `/detailed_keyword_graph.php`,
                val
            );
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);
export const detailedAction = createAsyncThunk(
    "data/detailedAction",
    async (val: any) => {
        try {
            const data = await API.post(`/func_detailed_serp.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const rankAction = createAsyncThunk(
    "data/rankAction",
    async (val: any) => {
        try {
            const data = await API.post(`/func_for_rank.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchDashboard = createAsyncThunk(
    "data/fetchDashboard",
    async (val: any) => {
        try {
            const data = await API.post(`/avg_position.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);

export const fetchSummary = createAsyncThunk(
    "data/fetchSummary",
    async (val: any) => {
        try {
            const data = await API.post(`/summary.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchSummary2 = createAsyncThunk(
    "data/fetchSummary2",
    async (val: any) => {
        try {
            const data = await API.post(`/url_summary.php`, val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const ranktrackerBacklinks = createAsyncThunk(
    "data/backlinks",
    async (id: any) => {
        try {
            const data = await API.post(`/crone_backlink.php`, id);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const ranktrackerBacklinksDashboard = createAsyncThunk(
    "data/backlinksDashboard",
    async (id: any) => {
        try {
            const data = await API.post(`/serp_backlink_dashboard.php`, id);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const calCompetitors = createAsyncThunk(
    "data/competitors",
    async (id: any) => {
        try {
            const data = await API.post(`/func_for_comp.php`, id);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const bulkcalCompetitors = createAsyncThunk(
    "data/bulkcompetitors",
    async (id: any) => {
        try {
            const data = await API.post(`/func_for_comp.php`, id);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const anchor = createAsyncThunk("data/Anchor", async (val?: any) => {
    try {
        const data = await API.post(`/anchor.php`, val);
        return data;
    } catch (error: any) {
        toast.error(error?.message);
    }
});
export const domainPages = createAsyncThunk(
    "data/domainpages",
    async (domain: any) => {
        try {
            const data = await API.post(`/rt_domain_pages.php`, domain);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const pageIntersection = createAsyncThunk(
    "data/pagination",
    async (domain: any) => {
        try {
            const data = await API.post(`/rt_page_intersection.php`, domain);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchTopFilters = createAsyncThunk(
    "data/fetchTopFilters",
    async (val: any) => {
        try {
            const data = await API.post("/top_filters.php", val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchHistoricalTopFilters = createAsyncThunk(
    "data/fetchHistoricalTopFilters",
    async (val: any) => {
        try {
            const data = await API.post("/historical_position.php", val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchProjectLimit = createAsyncThunk(
    "data/fetchProjectLimit",
    async (val: any) => {
        try {
            const data = await API.post("/limit_formula.php", val);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const rTBExports = createAsyncThunk("data/exports", async (val: any) => {
    try {
        const data: any = await API.post("/exports.php", val);
        const numbers = data.map((item:any, index:number) => {
            return {
                ...item,
                no: index + 1,
            };
        });
        return numbers;
    } catch (error: any) {
        toast.error(error?.message);
    }
});
const dataSlice = createSlice({
    name: "data",
    initialState: {
        state: [],
        exportsRTB: [],
        project: [],
        cities: [],
        language: [],
        other: [],
        locationloading: false,
        formStatus: "",
        vals: {},
        error: null,
        detailed: [],
        detailed2: [] || {},
        overAllDetailed: [],
        overAllDetailedLoading: false,
        histroicalDetailed: [],
        histroicalDetailedLoading: false,
        detailedLoading: false,
        detailedLoading2: false,
        projectInfo: {} as {
            id?: string;
            project?: string;
        },
        keywordDetails: {},
        projectLoading: false,
        fetchprojectsLoading: false,
        fetchProjects: [],
        deviceChart: [] || {},
        deviceChartLoading: false,
        detailActionLoading: false,
        recheckSetting: [],
        recheckDashboardSetting: [],
        dashboardGraph: [],
        dahsboardGraphLoading: false,
        summaryLoading: false,
        summaryData: [],
        summaryPages: [],
        summaryPagesLoading: false,
        projectGroups: [],
        keywordGroups: [],
        detailedActions: [] || {},
        backlinksLoading: false,
        backlinkss: [],
        competitors: [],
        backlinksDashboard: [],
        Anchors: [],
        domainPage: [],
        rankPageIntersection: [],
        bulkCompetitors: [],
        topFiltersLoading: false,
        topFilters: [],
        topFiltersHistoricalLoading: false,
        topFiltersHistorical: [],
        limit: {},
        limitLoading: false,
    } as any,
    reducers: {
        addProjectInfo: (state, action) => {
            const newProjectInfo = action.payload;
            const callback = () => {
                setTimeout(() => {
                    window.location.reload();
                }, 500);
            };
            state.projectInfo = newProjectInfo;
            callback();
        },
        recheckSettings: (state, action) => {
            state.recheckSetting = action.payload;
        },
        recheckDashboardSettings: (state, action) => {
            state.recheckDashboardSetting = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLocation.pending, (state) => {
                state.locationloading = true;
            })
            .addCase(getLocation.fulfilled, (state, action: any) => {
                state.locationloading = false;
                if (action.meta.arg[1] === "country") {
                    state.language = action.payload[0];
                    state.state = action.payload[1];
                } else if (action.meta.arg[1] === "state") {
                    state.cities = action.payload;
                } else if (action.meta.arg[1] === "city") {
                    state.other = action.payload;
                }
            })
            .addCase(getLocation.rejected, (state, action) => {
                state.locationloading = false;
            })
            .addCase(submitForm.pending, (state) => {
                state.locationloading = true;
            })
            .addCase(submitForm.fulfilled, (state, action) => {
                state.locationloading = false;
            })
            .addCase(submitForm.rejected, (state, action) => {
                state.locationloading = false;
            })
            .addCase(fetchDetailed.pending, (state) => {
                state.detailedLoading = true;
            })
            .addCase(fetchDetailed.fulfilled, (state, action) => {
                state.detailedLoading = false;
                state.detailed = action.payload as any;
            })
            .addCase(fetchDetailed.rejected, (state, action) => {
                state.detailedLoading = false;
            })
            .addCase(fetchDetailed2.pending, (state) => {
                state.detailedLoading2 = true;
            })
            .addCase(fetchDetailed2.fulfilled, (state, action) => {
                state.detailedLoading2 = false;
                state.detailed2 = action.payload as any;
            })
            .addCase(fetchDetailed2.rejected, (state, action) => {
                state.detailedLoading2 = false;
            })
            .addCase(fetchProjects.pending, (state) => {
                state.projectLoading = true;
            })
            .addCase(fetchProjects.fulfilled, (state, action: any) => {
                const projects = ensureArray(
                    ensureArray(action?.payload)
                        ?.reduce((accumulator: any, item: any, i: any) => {
                            if (i % 17 === 0) {
                                const object = {
                                    status: action.payload[i],
                                    group: action.payload[i + 1],
                                    id: action.payload[i + 2],
                                    project: action.payload[i + 3],
                                    top1: [
                                        action.payload[i + 4],
                                        action.payload[i + 7],
                                    ],
                                    top2: [
                                        action.payload[i + 5],
                                        action.payload[i + 8],
                                    ],
                                    top3: [
                                        action.payload[i + 6],
                                        action.payload[i + 9],
                                    ],
                                    keywords: action.payload[i + 10],
                                    avg: action.payload[i + 11],
                                    referring_domains: action.payload[i + 12],
                                    backlinks: action.payload[i + 13],
                                    updated_on: action.payload[i + 14],
                                    have_data: compareStr(
                                        action.payload[i + 15],
                                        1
                                    )
                                        ? true
                                        : false,
                                    date: action.payload[i + 16],
                                } as any;
                                accumulator.push(object);
                            }
                            return accumulator;
                        }, [])
                        .filter((el: any) => (el?.project ? true : false))
                );
                state.project = projects as any;
                state.projectLoading = false;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.projectLoading = false;
            })
            .addCase(fetchDeviceChart.pending, (state) => {
                state.deviceChartLoading = true;
            })
            .addCase(fetchDeviceChart.fulfilled, (state, action) => {
                state.deviceChartLoading = false;
                state.deviceChart = action.payload as any;
            })
            .addCase(fetchDeviceChart.rejected, (state, action) => {
                state.deviceChartLoading = false;
            })
            .addCase(detailedAction.pending, (state) => {
                state.detailActionLoading = true;
            })
            .addCase(detailedAction.fulfilled, (state, action) => {
                state.detailActionLoading = false;
                if (action.meta.arg.name === 2) {
                    state.projectGroups = action.payload as any;
                } else if (action.meta.arg.name === 7) {
                    state.keywordGroups = action.payload as any;
                } else {
                    state.detailedActions = action.payload as any;
                }
            })
            .addCase(detailedAction.rejected, (state, action) => {
                state.detailActionLoading = false;
            })
            .addCase(fetchOverAll.pending, (state) => {
                state.overAllDetailedLoading = true;
            })
            .addCase(fetchOverAll.fulfilled, (state, action) => {
                state.overAllDetailedLoading = false;
                state.overAllDetailed = action.payload as any;
            })
            .addCase(fetchOverAll.rejected, (state, action) => {
                state.overAllDetailedLoading = false;
            })
            .addCase(fetchHistorical.pending, (state) => {
                state.histroicalDetailedLoading = true;
            })
            .addCase(fetchHistorical.fulfilled, (state, action) => {
                state.histroicalDetailedLoading = false;
                state.histroicalDetailed = action.payload as any;
            })
            .addCase(fetchHistorical.rejected, (state, action) => {
                state.histroicalDetailedLoading = false;
            })
            .addCase(fetchDashboard.pending, (state) => {
                state.dahsboardGraphLoading = true;
            })
            .addCase(fetchDashboard.fulfilled, (state, action) => {
                state.dahsboardGraphLoading = false;
                state.dashboardGraph = action.payload as any;
            })
            .addCase(fetchDashboard.rejected, (state, action) => {
                state.dahsboardGraphLoading = false;
            })
            .addCase(fetchSummary.pending, (state) => {
                state.summaryLoading = true;
            })
            .addCase(fetchSummary.fulfilled, (state, action) => {
                state.summaryLoading = false;
                state.summaryData = ensureArray(action.payload) as any;
            })
            .addCase(fetchSummary.rejected, (state, action) => {
                state.summaryLoading = false;
            })

            .addCase(fetchSummary2.pending, (state) => {
                state.summaryPagesLoading = true;
            })
            .addCase(fetchSummary2.fulfilled, (state, action) => {
                state.summaryPagesLoading = false;
                state.summaryPages = action.payload as any;
            })
            .addCase(fetchSummary2.rejected, (state, action) => {
                state.summaryPagesLoading = false;
            })
            .addCase(rankAction.pending, (state, action) => {
                if (action.meta.arg?.name === "project") {
                    state.fetchprojectsLoading = true;
                }
            })
            .addCase(rankAction.fulfilled, (state, action) => {
                if (action.meta.arg?.name === "project") {
                    state.fetchprojectsLoading = false;
                    const data = ensureArray(action.payload)?.map((el) => {
                        return {
                            ...el,
                            have_data: compareStr(el?.data_status, 1)
                                ? true
                                : false,
                        };
                    });
                    state.fetchProjects = data as any;
                    if (
                        !data?.filter((el) => el?.id === state?.projectInfo?.id)
                            ?.length &&
                        data?.filter((el) => el?.have_data)?.length
                    ) {
                        state.projectInfo = data?.filter(
                            (el) => el?.have_data
                        )?.[0] as any;
                    } else if (
                        !data?.filter((el) => el?.have_data)?.length &&
                        state.projectInfo?.id
                    ) {
                        state.projectInfo = {};
                    }
                }
            })
            .addCase(rankAction.rejected, (state, action) => {
                if (action.meta.arg?.name === "project") {
                    state.fetchprojectsLoading = false;
                }
            })
            .addCase(ranktrackerBacklinks.pending, (state) => {
                state.backlinksLoading = true;
            })
            .addCase(ranktrackerBacklinks.fulfilled, (state, action) => {
                state.backlinksLoading = false;
                state.backlinkss = action.payload as any;
            })
            .addCase(ranktrackerBacklinks.rejected, (state, action) => {
                state.backlinksLoading = false;
            })
            .addCase(calCompetitors.pending, (state) => {
                state.backlinksLoading = true;
            })
            .addCase(calCompetitors.fulfilled, (state, action) => {
                state.backlinksLoading = false;
                state.competitors = action.payload as any;
            })
            .addCase(calCompetitors.rejected, (state, action) => {
                state.backlinksLoading = false;
            })
            .addCase(ranktrackerBacklinksDashboard.pending, (state) => {
                state.backlinksLoading = true;
            })
            .addCase(
                ranktrackerBacklinksDashboard.fulfilled,
                (state, action) => {
                    state.backlinksLoading = false;
                    state.backlinksDashboard = action.payload as any;
                }
            )
            .addCase(
                ranktrackerBacklinksDashboard.rejected,
                (state, action) => {
                    state.backlinksLoading = false;
                }
            )
            .addCase(anchor.pending, (state) => {
                state.backlinksLoading = true;
            })
            .addCase(anchor.fulfilled, (state, action) => {
                state.backlinksLoading = false;
                state.Anchors = action.payload as any;
            })
            .addCase(anchor.rejected, (state, action) => {
                state.backlinksLoading = false;
            })
            .addCase(domainPages.pending, (state) => {
                // state.backlinksLoading = true;
            })
            .addCase(domainPages.fulfilled, (state, action) => {
                // state.backlinksLoading = false;
                state.Anchors = action.payload as any;
            })
            .addCase(domainPages.rejected, (state, action) => {
                // state.backlinksLoading = false;
            })
            .addCase(pageIntersection.pending, (state) => {
                state.backlinksLoading = true;
            })
            .addCase(pageIntersection.fulfilled, (state, action) => {
                state.backlinksLoading = false;
                state.rankPageIntersection = action.payload as any;
            })
            .addCase(pageIntersection.rejected, (state, action) => {
                // state.backlinksLoading = false;
            })
            .addCase(bulkcalCompetitors.pending, (state) => {
                // state.backlinksLoading = true;
            })
            .addCase(bulkcalCompetitors.fulfilled, (state, action) => {
                // state.backlinksLoading = false;
                state.bulkCompetitors = action.payload as any;
            })
            .addCase(bulkcalCompetitors.rejected, (state, action) => {
                // state.backlinksLoading = false;
            })
            .addCase(fetchTopFilters.pending, (state) => {
                state.topFiltersLoading = true;
            })
            .addCase(fetchTopFilters.fulfilled, (state, action) => {
                state.topFiltersLoading = false;
                state.topFilters = action.payload as any;
            })
            .addCase(fetchTopFilters.rejected, (state) => {
                state.topFiltersLoading = false;
            })
            .addCase(fetchHistoricalTopFilters.pending, (state) => {
                state.topFiltersHistoricalLoading = true;
            })
            .addCase(fetchHistoricalTopFilters.fulfilled, (state, action) => {
                state.topFiltersHistoricalLoading = false;
                state.topFiltersHistorical = action.payload as any;
            })
            .addCase(fetchHistoricalTopFilters.rejected, (state) => {
                state.topFiltersHistoricalLoading = false;
            })
            .addCase(fetchProjectLimit.pending, (state) => {
                state.limitLoading = true;
            })
            .addCase(fetchProjectLimit.fulfilled, (state, action) => {
                state.limitLoading = false;
                state.limit = action.payload as any;
            })
            .addCase(fetchProjectLimit.rejected, (state) => {
                state.limitLoading = false;
            })
            .addCase(rTBExports.pending, (state) => {
                state.limitLoading = true;
            })
            .addCase(rTBExports.fulfilled, (state, action) => {
                state.limitLoading = false;
                state.exportsRTB = action.payload as any;
            })
            .addCase(rTBExports.rejected, (state) => {
                state.limitLoading = false;
            });
    },
});
export const { addProjectInfo, recheckSettings, recheckDashboardSettings } =
    dataSlice.actions;

export default dataSlice.reducer;
// fetchSummary

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import API from "src/utils/authConfig";

export const suggetions = createAsyncThunk("data/submit", async (val: any) => {
    try {
        if (Object.keys(val).length !== 0) {
            // console.log(val,"request value")
            const data = await API.post("/suggestion.php", val);
            // console.log(data,"data1")
            return data;
        } 
        else {
            const data = await API.get("/suggestion.php");
            return data;
        }
    } catch (error: any) {
        toast.error(error.message);
    }
});
export const keyWordSuggetions = createAsyncThunk("data/keyword", async () => {
    try {
        const data = await API.get("/suggestion.php");
        return data;
    } catch (error: any) {
        toast.error(error.message);
    }
});

export const sub_suggestion = createAsyncThunk(
    "sub_suggestion/submit",
    async (val: any) => {
        try {
            const data = await API.post("/sub_suggestion.php", val);
            return data;
        } catch (error: any) {
            toast.error(error.message);
        }
    }
);

const dataSlice = createSlice({
    name: "data",
    initialState: {
        keywodsSuggetion: [],
        subSuggestion: [],
        subSuggestion2: [],
        keywordDetails: "",
        status: "idle",
        formStatus: "",
        suggetionsStatus: "",
        keywodsSuggetionStatus: false,
        error: null,
        language: "",
    } as any,
    reducers: {
        addkeywordDetails: (state, action) => {
            state.keywordDetails = action.payload;
        },
        emptySubSuggestion: (state, action) => {
            state.subSuggestion = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(suggetions.pending, (state) => {
                state.keywodsSuggetionStatus = true;
            })
            .addCase(suggetions.fulfilled, (state, action) => {
                state.keywodsSuggetionStatus = false;
                state.keywodsSuggetion = action.payload;
            })
            .addCase(suggetions.rejected, (state, action) => {
                state.keywodsSuggetionStatus = false;
            })
            .addCase(keyWordSuggetions.pending, (state) => {
                state.keywodsSuggetionStatus = true;
            })
            .addCase(keyWordSuggetions.fulfilled, (state, action) => {
                state.keywodsSuggetionStatus = false;
                state.keywodsSuggetion = action.payload;
            })
            .addCase(keyWordSuggetions.rejected, (state, action) => {
                state.keywodsSuggetionStatus = false;
            })
            .addCase(sub_suggestion.pending, (state) => {
                state.formStatus = true;
            })
            .addCase(sub_suggestion.fulfilled, (state, action) => {
                state.formStatus = false;
                // if (action.meta.arg.type === "static") {
                state.subSuggestion = action.payload;
                // } else if (action.meta.arg.type === "dynamic") {
                //     state.subSuggestion2 = action.payload;
                // }
            })
            .addCase(sub_suggestion.rejected, (state, action) => {
                state.formStatus = false;
            });
    },
});
export const { addkeywordDetails, emptySubSuggestion } = dataSlice.actions;
export default dataSlice.reducer;

import { Box, Stack } from "@mui/material";
import Aisde from "./aside";

interface IProps {
    aside?: "minimize";
    sidebarLayout?: 1 | 2;
    children: React.ReactNode;
}

const Layout = ({ children, aside, sidebarLayout }: IProps) => {
    return (
        <Box position={"relative"}>
            <Aisde />
            {children}
        </Box>
    );
};

Layout.defaultProps = {
    sidebarLayout: 1,
};

export default Layout;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { ensureArray } from "src/helpers/formater-helper";
import API from "src/utils/authConfig";

export const requestHistory = createAsyncThunk(
    "data/requestHistory",
    async (values: any) => {
        try {
            const data = await API.post("/request_history.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);

const dataSlice = createSlice({
    name: "account",
    initialState: {
        requestsGraphs: [],
        requestsGraphsLoading: false,
        requestsTable: [],
        requestsTableLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(requestHistory.pending, (state) => {
                state.requestsGraphsLoading = true;
                state.requestsTableLoading = true;
            })
            .addCase(requestHistory.fulfilled, (state, action) => {
                state.requestsGraphsLoading = false;
                state.requestsTableLoading = false;
                state.requestsTable = ensureArray(action.payload) as any;
            })
            .addCase(requestHistory.rejected, (state) => {
                state.requestsGraphsLoading = false;
                state.requestsTableLoading = false;
            });
    },
});

export default dataSlice.reducer;

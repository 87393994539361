import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { getRoleByNumber } from "src/helpers/data-helper";
import { ensureArray } from "src/helpers/formater-helper";
import API from "src/utils/authConfig";

export const usersManagement = createAsyncThunk(
    "data/usersManagement",
    async (values: any) => {
        try {
            const data = await API.post("/user_management.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);

const dataSlice = createSlice({
    name: "admin",
    initialState: {
        usersLoading: false,
        users: [],
        updateUserLoading: false,
        addUserLoading: false,
        updateStatus: false,
        deleteLoading: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(usersManagement.pending, (state, action) => {
                const {
                    meta: {
                        arg: { type },
                    },
                } = action;
                switch (type) {
                    case 1:
                        state.addUserLoading = true;
                        break;
                    case 2:
                        state.updateStatus = true;
                        break;
                    case 3:
                        state.deleteLoading = true;
                        break;
                    case 4:
                        state.updateUserLoading = true;
                        break;
                    default:
                        state.usersLoading = true;
                        break;
                }
            })
            .addCase(usersManagement.fulfilled, (state, action) => {
                const {
                    meta: {
                        arg: { type },
                    },
                    payload,
                } = action;
                switch (type) {
                    case 1:
                        state.addUserLoading = false;
                        break;
                    case 2:
                        state.updateStatus = false;
                        break;
                    case 3:
                        state.deleteLoading = false;
                        break;
                    case 4:
                        state.updateUserLoading = false;
                        break;
                    default:
                        state.usersLoading = false;
                        break;
                }
                state.users = ensureArray(payload?.data)?.map((el) => {
                    return {
                        ...el,
                        name: `${el?.first_name} ${el?.last_name ?? ""}`,
                        u_type: getRoleByNumber(el?.u_type),
                    };
                }) as any;
            })
            .addCase(usersManagement.rejected, (state, action) => {
                const {
                    meta: {
                        arg: { type },
                    },
                } = action;
                switch (type) {
                    case 1:
                        state.addUserLoading = false;
                        break;
                    case 2:
                        state.updateStatus = false;
                        break;
                    case 3:
                        state.deleteLoading = false;
                        break;
                    case 4:
                        state.updateUserLoading = false;
                        break;
                    default:
                        state.usersLoading = false;
                        break;
                }
            });
    },
});

export default dataSlice.reducer;

import {
    Actions,
    PayloadAction,
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import { dataFixer2 } from "src/helpers/data-helper";
import { generateString, generateUUID } from "src/helpers/data-makers";
import {
    compareStr,
    ensureArray,
    ensureObject,
    extractDomain,
    getRandomNumber,
    slugify,
} from "src/helpers/formater-helper";
import { convertToApexChartData } from "src/helpers/graph-helper";
import API from "src/utils/authConfig";

export const fetchAddedOverallCompTable = createAsyncThunk(
    "data/fetchAddedCompTable",
    async (values: any) => {
        try {
            const data = await API.post("/added_comp_se.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchAddedDetailedCompTable = createAsyncThunk(
    "data/fetchAddedDetailedCompTable",
    async (values: any) => {
        try {
            const data = await API.post("/added_comp_sdt.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchAddedGraphComp = createAsyncThunk(
    "data/fetchAddedGraphComp",
    async (values: any) => {
        try {
            const data = await API.post("/added_comp_graphs.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const addedCompAction = createAsyncThunk(
    "addedCompAction",
    async (values: any) => {
        try {
            const data = await API.post("/func_for_comp.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchSerpComp = createAsyncThunk(
    "fetchSerpComp",
    async (values: any) => {
        try {
            const data = await API.post("/serp_competitor.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchVisibilityRating = createAsyncThunk(
    "fetchVisibilityRating",
    async (values: any) => {
        try {
            const data = await API.post("/visibility.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);
export const fetchVisibilityGraph = createAsyncThunk(
    "fetchVisibilityGraph",
    async (values: any) => {
        try {
            const data = await API.post("/visibility_graph.php", values);
            return data;
        } catch (error: any) {
            toast.error(error?.message);
        }
    }
);

const dataSlice = createSlice({
    name: "myCompetitors",
    initialState: {
        AddedOverallCompTable: [],
        AddedOverallCompLoading: false,
        AddedDetailedCompTable: [],
        AddedDetailedCompLoading: false,
        AddedGraphComp: [],
        AddedGraphCompLoading: false,
        AddedSettings: [],
        AddedCompActionData: {} || [],
        addedCompActionLoading: false,
        tags: [],
        serpCompLoading: false,
        serpComp: {},
        trackerInfo: {} as {
            keywords: number | null;
            competitors: number | null;
            progress: number | null;
        },
        keywordsList: [],
        keywordListLoading: false,
        domainTags: [],
        domainTagsLoading: false,
        competitor: "",
        visibility: [],
        visibilityGraph: {
            series: [],
            categories: [],
        } as {
            series: any[];
            categories: any[];
        },
        visibilityGraphLoading: false,
        visibilityLoading: false,
    },
    reducers: {
        addAddedSettings: (state, action) => {
            state.AddedSettings = action.payload;
        },
        compareComp: (state, action: PayloadAction<string>) => {
            state.competitor = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAddedOverallCompTable.pending, (state) => {
                state.AddedOverallCompLoading = true;
            })
            .addCase(fetchAddedOverallCompTable.fulfilled, (state, action) => {
                state.AddedOverallCompLoading = false;
                state.AddedOverallCompTable = [
                    ensureArray(ensureArray(action.payload)?.[0]).map((el) => {
                        return {
                            ...el,
                            id: el?.search_engine,
                        };
                    }),
                ] as any;
            })
            .addCase(fetchAddedOverallCompTable.rejected, (state, action) => {
                state.AddedOverallCompLoading = false;
            })
            .addCase(fetchAddedDetailedCompTable.pending, (state) => {
                state.AddedDetailedCompLoading = true;
            })
            .addCase(fetchAddedDetailedCompTable.fulfilled, (state, action) => {
                state.AddedDetailedCompLoading = false;
                const data = ensureArray(action?.payload);
                state.AddedDetailedCompTable = data?.reduce(
                    (accumulator, item, i) => {
                        if (i % 16 === 0) {
                            const object = {
                                id: data[i + 1] + "|$|" + data[i],
                                competitor: data[i + 1],
                                top1: [data[i + 2], data[i + 5]],
                                top2: [data[i + 3], data[i + 6]],
                                top3: [data[i + 4], data[i + 7]],
                                keywords: data[i + 8],
                                avg: data[i + 9],
                                avg_dynamic: data[i + 10],
                                dynamics: [data[i + 11], data[i + 12]],
                                referring_domains: data[i + 13],
                                backlinks: data[i + 14],
                                updated_on: data[i + 15],
                            };
                            accumulator.push(object);
                        }
                        return accumulator;
                    },
                    []
                );
            })
            .addCase(fetchAddedDetailedCompTable.rejected, (state, action) => {
                state.AddedDetailedCompLoading = false;
            })
            .addCase(fetchAddedGraphComp.pending, (state) => {
                state.AddedGraphCompLoading = true;
            })
            .addCase(fetchAddedGraphComp.fulfilled, (state, action) => {
                state.AddedGraphCompLoading = false;
                state.AddedGraphComp = action.payload as any;
            })
            .addCase(fetchAddedGraphComp.rejected, (state, action) => {
                state.AddedGraphCompLoading = false;
            })
            .addCase(addedCompAction.pending, (state, action) => {
                state.addedCompActionLoading = true;
                if (action.meta.arg.name === 12) {
                    state.keywordListLoading = true;
                } else if (action.meta.arg.name === 33) {
                    state.domainTagsLoading = true;
                }
            })
            .addCase(addedCompAction.fulfilled, (state, action) => {
                state.addedCompActionLoading = false;
                if (action.meta.arg.name === 2) {
                    state.trackerInfo = action.payload as any;
                } else if (action.meta.arg.name === 4) {
                    state.tags = dataFixer2(ensureArray(action.payload)) as any;
                } else if (action.meta.arg.name === 12) {
                    state.keywordsList = action.payload as any;
                    state.keywordListLoading = false;
                } else if (action.meta.arg.name === 33) {
                    const mainData = ensureArray(action.payload);
                    const updatedData = [
                        ...new Set(
                            mainData?.map(({ tag_name, color }) =>
                                JSON.stringify({ tag_name, color })
                            )
                        ),
                    ].map((str) => ({
                        ...JSON.parse(str),
                        id: generateUUID(),
                        domains: mainData
                            .filter(
                                ({ tag_name, color }) =>
                                    tag_name === JSON.parse(str)?.tag_name &&
                                    color === JSON.parse(str)?.color
                            )
                            .map(({ domain, url, type }) => ({
                                domain,
                                url,
                                type,
                            })),
                    })) as any;
                    state.domainTags = updatedData;
                    state.domainTagsLoading = false;
                }
            })
            .addCase(addedCompAction.rejected, (state, action) => {
                state.addedCompActionLoading = false;
                if (action.meta.arg.name === 12) {
                    state.keywordListLoading = true;
                }
            })
            .addCase(fetchSerpComp.pending, (state) => {
                state.serpCompLoading = true;
            })
            .addCase(fetchSerpComp.fulfilled, (state, action) => {
                state.serpCompLoading = false;
                state.serpComp = action.payload as any;
            })
            .addCase(fetchSerpComp.rejected, (state) => {
                state.serpCompLoading = true;
            })
            .addCase(fetchVisibilityRating.pending, (state) => {
                state.visibilityLoading = true;
            })
            .addCase(fetchVisibilityRating.fulfilled, (state, action) => {
                state.visibilityLoading = false;
                state.visibility = ensureArray(action.payload)?.map((el) => {
                    return {
                        ...el,
                        id: el?.domain,
                    };
                }) as any;
            })
            .addCase(fetchVisibilityRating.rejected, (state) => {
                state.visibilityLoading = false;
            })
            .addCase(fetchVisibilityGraph.pending, (state) => {
                state.visibilityGraphLoading = true;
            })
            .addCase(fetchVisibilityGraph.fulfilled, (state, action) => {
                state.visibilityGraph = compareStr(
                    "competitor_distribution",
                    action?.meta.arg.name
                )
                    ? {
                          series: [
                              {
                                  type: "bar",
                                  name: "Total Keywords in top 10",
                                  data: Object.values(
                                      ensureObject(action?.payload)
                                  )?.map(Number),
                              },
                          ],
                          categories: Object.keys(
                              ensureObject(action?.payload)
                          ),
                      }
                    : convertToApexChartData(action?.payload as any);
                state.visibilityGraphLoading = false;
            })
            .addCase(fetchVisibilityGraph.rejected, (state) => {
                state.visibilityGraphLoading = false;
            });
    },
});
export const { addAddedSettings, compareComp } = dataSlice.actions;
export default dataSlice.reducer;
